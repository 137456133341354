import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";
import ReactDOM from 'react-dom/client';

import AccountModal from '../src/Components/Modals/AccountModal';
import SendLaterModal from '../src/Components/Modals/SendLaterModal';
import store from './redux/store';
import App from './App';

import './assets/scss/app.scss'

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
      <AccountModal />
      <SendLaterModal />
    </Provider>
  </React.StrictMode>
);