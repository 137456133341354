import React, { useContext, useEffect, useRef, useState } from 'react'
import { Accordion, AccordionContext, useAccordionButton } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { SvgArrowDown, SvgDelete, SvgForwardMsg, SvgReplyMsg } from '../../../../utils/IconInfo';
import { formatDate } from '../../../../api/helper';
import Compose from '../../Compose/Compose';
import MediaFiles from '../../MediaFiles/MediaFiles';
import { deleteMessage } from '../../../../api';

const useOutSideDetect = (ref, setSelectedDropMenuSectionId) => {
    useEffect(() => {
        const handleClickOutSide = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setSelectedDropMenuSectionId('');
            }
        }
        document.addEventListener('mousedown', handleClickOutSide);
        return () => {
            document.removeEventListener('mousedown', handleClickOutSide);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref])
    
}

const MessageItem = ({
    index,
    eventKey,
    thread,
    message,
    userEmail,
    createMarkup,
    callback,
}) => {
    
  const { activeEventKey } = useContext(AccordionContext);
  const quickMenuRef = useRef(null)
  
  const [selectedDropMenuSectionId, setSelectedDropMenuSectionId] = useState('');
  const [composeType, setComposeType] = useState('none');
  const [isBottomBtnSectionVisible, setIsBottomBtnSectionVisible] = useState(false)
  
  const currentEmailBox = useSelector((state) => state.reducer.currentEmailBox);
  const accountId = useSelector((state) => state.reducer.account_id);

  useOutSideDetect(quickMenuRef, setSelectedDropMenuSectionId);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = activeEventKey?.includes(eventKey);

  const handleDeleteMessage = async (messageId) => {
    await deleteMessage(accountId, messageId);
  }
  
  useEffect(() => {
    if (index === thread.length - 1 && composeType === 'none') {
        setIsBottomBtnSectionVisible(true);
    } else {
        setIsBottomBtnSectionVisible(false);
    }
  }, [index, thread.length, composeType])
  
  
  return (
    <>
        <div
          role="button"
          style={{ 
            backgroundColor: isCurrentEventKey ? "white" : "#F5F7F9",
            padding: "15px",
          }}
          onClick={() => {eventKey === thread[thread?.length - 1].id ? void 0 : decoratedOnClick()}}
        >
            <div className="media media-thread">
              <div className="media-left">
                  <span className='avatar avatar-md avatar-alphabet'>{message.from.name[0].toUpperCase()}</span>
              </div>
              <div className="media-body">
                  <div className='row'>
                      <div className='col'>
                          <div className='row media-subject-header'>
                              <div className='col-auto media-subject'>
                                  <div style={{ display: 'flex' }}>
                                      <strong>{message.from.name}&nbsp;&nbsp;</strong>
                                  </div>
                                  {/* {currentEmailBox === 'sent' && <div style={{ fontWeight: 400, color: 'grey' }}>to : {message.to[0].email === userEmail ? 'me' : message.to[0].name}</div>} */}
                              </div>
                          </div>
                      </div>
                      <div className='col-auto'>
                          <div className='media-option'>
                              <div className='media-option-item'>
                                  <small className="media-dayTime text-black f-14">{formatDate(message.date_time)}</small>
                              </div>

                              {isCurrentEventKey &&
                                  <div style={{display: 'flex', position: 'relative'}}>
                                      <div className='media-option-item reply-option-button' onClick={(e) => { e.stopPropagation(); setSelectedDropMenuSectionId(''); setComposeType('reply'); }}>
                                          <SvgReplyMsg className='icon-button' width="18px" height='18px' color='#5F6267' />
                                      </div>
                                      <div className='media-option-item reply-option-button' onClick={(e) => { e.stopPropagation(); setSelectedDropMenuSectionId(eventKey); }}>
                                          <SvgArrowDown className='icon-button' width="15px" height='15px' color='#373435' />
                                      </div>
                                      {selectedDropMenuSectionId === eventKey &&
                                          <div ref={quickMenuRef} className='reply-option-section'>
                                              <div onClick={(e) => { e.stopPropagation(); setSelectedDropMenuSectionId(''); setComposeType('reply') }}>
                                                  <SvgReplyMsg color='#373435' />
                                                  <span>Reply</span>
                                              </div>
                                              <div onClick={(e) => { e.stopPropagation(); setSelectedDropMenuSectionId(''); setComposeType('replyToAll') }}>
                                                  <SvgReplyMsg color='#373435' />
                                                  <span>Reply to All</span>
                                              </div>
                                              <div onClick={(e) => { e.stopPropagation(); setSelectedDropMenuSectionId(''); setComposeType('forward') }}>
                                                  <SvgForwardMsg color='#373435' />
                                                  <span>Forward</span>
                                              </div>
                                              <div onClick={(e) => { e.stopPropagation(); setSelectedDropMenuSectionId(''); handleDeleteMessage(message.id); }}>
                                                  <SvgDelete color='#373435' />
                                                  <span>Delete</span>
                                              </div>
                                          </div>
                                      }
                                  </div>
                              }
                          </div>
                      </div>
                  </div>
                  <div className="media-msz">{message.subject}</div>
              </div>
          </div>
        </div>
        
        <div style={{ marginLeft: "110px" }}>
          <Accordion.Collapse eventKey={eventKey} defaultChecked>
            <div>
                <div className='note-editor-view' dangerouslySetInnerHTML={createMarkup(message)} />
                <p></p>
                {message.attachments?.length > 0 && <MediaFiles attachments={message.attachments} messageId={eventKey} />}
                
                {isCurrentEventKey && composeType !== 'none' &&
                    <div className="position-relative mt-50">
                        <div className="position-absolute" style={{left: "-90px"}}>
                            <div className="media-left"><span className='avatar avatar-md avatar-alphabet'>{userEmail[0].toUpperCase()}</span></div>
                        </div>
                        <div className='note-editor w-100'>
                            <Compose composeType={composeType} setComposeType={setComposeType} thread={thread} messageIndex={index} />
                        </div>
                    </div>
                }
            </div>
          </Accordion.Collapse>
        </div>
        {isBottomBtnSectionVisible ?
            <div className="media media-editor">
                <div className="media-body">
                        <div className='btn-toolbar'>
                            <a href='#/' className='btn btn-whitesmoke-dark' onClick={() => { setComposeType("replyToAll") }}>
                                <span className='icon'><SvgReplyMsg className='icon-reply' color='#373435' /></span>
                                <span className='text'>Reply to All</span>
                            </a>
                            <a href='#/' className='btn btn-whitesmoke-dark' onClick={() => { setComposeType("reply") }}>
                                <span className='icon'><SvgReplyMsg className='icon-reply' color='#373435' /></span>
                                <span className='text'>Reply</span>
                            </a>
                            <a href='#/' className='btn btn-whitesmoke-dark' onClick={() => { setComposeType("forward") }}>
                                <span className='icon'><SvgForwardMsg className='icon-forward' color='#373435' /></span>
                                <span className='text'>Forward</span>
                            </a>
                        </div>
                </div>
            </div>
            :
            null
        }
    </>
  );
}

export default MessageItem;