import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import { selectEmails, setIsDeleteConfirmModal, setSelectedMsgCount, setSelectedUnreadMsgCount, setTotalMsgCountData, setUnreadMsgCoundData } from "../../../action/index.js";
import { formatDate } from '../../../api/helper.js';
import { getGoogleMenuItems } from '../../../api/index.js';

import { SvgArrowLeft, SvgArrowRight, SvgCalendar, SvgCheck, SvgClose, SvgDelete, SvgFilter, SvgSpam, SvgRefresh, SvgSettings } from '../../../utils/IconInfo.js';

import "./style.scss";
import DeleteConfirmModal from '../../Modals/DeleteConfirmModal.js';

const EmailTopbar = (props) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isSearching, setSearching] = useState(false);
    const [isAdvancedSearching, setAdvancedSearching] = useState(false);
    const [hasAttachment, setHasAttachment] = useState(false);
    const [isFromMe, setFromMe] = useState(false);
    const [last7Days, setLast7Days] = useState(false);
    const [keyword, setKeyword] = useState(props.keyword !== undefined ? props.keyword : "");
    const [attachment, setAttachment] = useState("All");
    const [folder, setFolder] = useState("All");
    const [dt, setDt] = useState("All");
    const [userEmail, setUserEmail] = useState("")
    const [sugEmailsList, setSugEmailsList] = useState([])

    const state = useSelector((state) => state.reducer);
    const accountId = useSelector((state) => state.reducer.account_id);
    const accountsList = useSelector((state) => state.reducer.emailsList);
    const totalMsgCountData = useSelector((state) => state.reducer.totalMsgCountData);
    const selectedMsgCount = useSelector((state) => state.reducer.selectedMsgCount);
    const unReadMsgCountData = useSelector((state) => state.reducer.unReadMsgCountData);
    const selectedUnreadMsgCount = useSelector((state) => state.reducer.selectedUnreadMsgCount);
    const emailMenuItems = useSelector((state) => state.reducer.emailMenuItems);

    const onRefresh = () => {
        if (props.onRefresh) {
            props.onRefresh();
        }
    }
    const onDelete = () => {
        if (props.box === 'trash' || props.box === 'spam') {
            dispatch(setIsDeleteConfirmModal(true));
        } else {
            if (props.onDelete) {
                props.onDelete();
            }
            updateMsgCountValue('del');
        }
    }
    const onModalOk = () => {
        if (props.onDelete) {
            props.onDelete();
        }
        updateMsgCountValue('foreverDel');
    }
    const onSpam = async () => {
        if (props.onSpam) {
            props.onSpam();
        }
        updateMsgCountValue('spam');
    }
    const onNotSpam = async () => {
        if (props.onNotSpam) {
            props.onNotSpam();
        }
        updateMsgCountValue('notSpam');
    }
    const onSelectMails = (opt) => {
        dispatch(selectEmails(props.box, opt));
    }
    const gotoSearch = () => {
        let params = {};
        if (!isAdvancedSearching) {
            if (keyword) params['q'] = keyword.trim();
            if (isFromMe) params['from_me'] = true;
            if (hasAttachment) params['attachments'] = "with";
            if (last7Days) params['date'] = "last_7_day";
            // params['labelId'] = props.box.toUpperCase()
        } else {
            if (keyword) params['q'] = keyword.trim();
            if (attachment !== 'All') params['attachments'] = attachment;
            if (dt !== 'All') params['date'] = dt;
            if (folder !== 'All') params['labelId'] = folder;
        }
        if (props.box !== 'search') {
            navigate("/email/search", { state: { 'params': params, "keyword": keyword } });
        } else {
            props.onUpdateParams(params);
        }
    }
    const onKeyPressSearch = (e) => {
        if (e.key === "Enter" && (hasAttachment || isFromMe || last7Days || !!keyword.trim())) {
            setSearching(false);
            gotoSearch();
        } else if (e.key === "Escape") {
            setSearching(false);
            setAdvancedSearching(false);
            setKeyword("");
        }
    }
    const onSearch = () => {
        setSearching(false);
        gotoSearch();
    }
    const onAttachmentChange = (event) => {
        setAttachment(event.target.value);
    }
    const suggestionData = () => {
        const emailsList = state[props.box]?.emails
        let userEmail;
        accountsList.accounts.map((item) => {
            if (item.account_id === accountId) { userEmail = item.email }
        })
        setUserEmail(userEmail);

        let resultMsgList = emailsList ? [...emailsList] : [];
        if (hasAttachment) {
            resultMsgList = resultMsgList.filter((item) => item.has_attachments);
        }
        if (isFromMe) {
            resultMsgList = resultMsgList.filter((item) => item.from.email === userEmail);
        }
        if (last7Days) {
            resultMsgList = resultMsgList.filter((item) => {
                const today = new Date();
                const receiveDate = new Date(item.date_time)
                const duringDate = Math.floor(today.getTime() - (receiveDate.getTime())) / (1000 * 60 * 60 * 24)
                if (duringDate < 7) return true;
                else return false;
            });
        }
        if (!!keyword) {
            resultMsgList = resultMsgList.filter((item) => {
                if (`${item.from.name?.toUpperCase()}`.includes(keyword?.toUpperCase()) ||
                    `${item.from.email?.toUpperCase()}`.includes(keyword?.toUpperCase()) ||
                    `${item.to.name?.toUpperCase()}`.includes(keyword?.toUpperCase()) ||
                    `${item.to.email?.toUpperCase()}`.includes(keyword?.toUpperCase()) ||
                    `${item.subject?.toUpperCase()}`.includes(keyword?.toUpperCase()) ||
                    `${item.snippet?.toUpperCase()}`.includes(keyword?.toUpperCase())) {
                    return true;
                } else return false;
            });
        }
        if (!hasAttachment && !isFromMe && !last7Days && keyword === "") resultMsgList = [];
        setSugEmailsList(resultMsgList);
    }
    const updateMsgCountValue = async (type) => {
        dispatch(setTotalMsgCountData({
            ...totalMsgCountData,
            [props.box]: totalMsgCountData[props.box] - selectedMsgCount
        }));

        if (type === 'del') {
            const updatedMessageCount = {
                ...unReadMsgCountData,
                [props.box]: unReadMsgCountData[props.box] - selectedUnreadMsgCount,
                trash: unReadMsgCountData.trash + selectedUnreadMsgCount
            };
            dispatch(setUnreadMsgCoundData(updatedMessageCount))
        } else if (type === 'foreverDel') {
            const updatedMessageCount = {
                ...unReadMsgCountData,
                [props.box]: unReadMsgCountData[props.box] - selectedUnreadMsgCount
            };
            dispatch(setUnreadMsgCoundData(updatedMessageCount))
        } else if (type === 'spam') {
            const updatedMessageCount = {
                ...unReadMsgCountData,
                [props.box]: unReadMsgCountData[props.box] - selectedUnreadMsgCount,
                spam: unReadMsgCountData.spam + selectedUnreadMsgCount
            };
            dispatch(setUnreadMsgCoundData(updatedMessageCount))
        } else if (type === 'notSpam') {
            const updatedMessageCount = {
                ...unReadMsgCountData,
                [props.box]: unReadMsgCountData[props.box] - selectedUnreadMsgCount,
                inbox: unReadMsgCountData.inbox + selectedUnreadMsgCount
            };
            dispatch(setUnreadMsgCoundData(updatedMessageCount))
        }

        dispatch(setSelectedMsgCount());
        dispatch(setSelectedUnreadMsgCount());
    }

    useEffect(() => {
        const fetch = async () => {
            if (accountId !== 0) {
                try {
                    const menuItems = await getGoogleMenuItems(accountId)

                    let updatedTotalMsgCountData = totalMsgCountData;
                    let updatedUnreadMsgCountData = unReadMsgCountData;

                    menuItems.map((menuItem) => {
                        emailMenuItems.map((availMenuItem) => {
                            if (menuItem.id === availMenuItem) {
                                updatedTotalMsgCountData = {
                                    ...updatedTotalMsgCountData,
                                    [availMenuItem.toLowerCase()]: menuItem.total_count
                                }
                            }
                            if (menuItem.id === availMenuItem) {
                                updatedUnreadMsgCountData = {
                                    ...updatedUnreadMsgCountData,
                                    [availMenuItem.toLowerCase()]: menuItem.unread_count
                                }
                            }
                        })
                    })

                    dispatch(setTotalMsgCountData(updatedTotalMsgCountData));
                    dispatch(setUnreadMsgCoundData(updatedUnreadMsgCountData));
                } catch (err) {
                    console.error(err);
                }
            }
        }

        fetch();
        updateMsgCountValue();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountId, location.pathname])

    useEffect(() => {
        if (isSearching) {
            suggestionData();
        } else {
            setSugEmailsList([]);
            setHasAttachment(false);
            setFromMe(false);
            setLast7Days(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSearching, isFromMe, hasAttachment, last7Days, keyword])

    return (
        <div className="media-list-header flexbox">
            <div className="media-header-left">
                {location.pathname !== "/email/settings" ?
                    <>
                        {!location.pathname.includes('thread') &&
                            <>
                                <div className='media-header-item'>
                                    <div className="btn-group">
                                        <button className="btn btn-link link-gray px-0" onClick={() => { onRefresh(); }}>
                                            <SvgRefresh className='icon icon-refresh' color="#898989" />
                                            <span className='text'>Refresh</span>
                                        </button>
                                    </div>
                                </div>
                                <div className='media-header-item px-0'>
                                    <span className="divider-line"></span>
                                </div>
                            </>
                        }
                        {(location.pathname !== "/email/spam" && location.pathname !== "/email/trash") &&
                            <>
                                {!location.pathname.includes('thread') &&
                                    <div className='media-header-item'>
                                        <div className='check-box' ></div>
                                        <Dropdown as={ButtonGroup} className="dropdown-checkbox">
                                            {/* <button className="btn btn-link px-0">
                                            <div className="form-check custom-checkbox mb-0">
                                                <input className="form-check-input selected" type="checkbox" value="" />
                                                <label className="form-check-label"></label>
                                            </div>
                                        </button> */}
                                            <Dropdown.Toggle variant="transparent" className='btn-link link-gray px-0' />
                                            <Dropdown.Menu className='dropdown-menu'>
                                                {['All', 'None', 'Read', 'Unread'].map((item) =>
                                                    <Dropdown.Item key={item} onClick={() => onSelectMails(item)}>{item}</Dropdown.Item>
                                                )}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                }
                                {(selectedMsgCount > 0 || location.pathname.includes('thread')) &&
                                    <>
                                        <div className='media-header-item'>
                                            <div className='delete-btn' onClick={() => { onDelete(); }} >
                                                <SvgDelete className='icon icon-delete' color="#898989" />
                                            </div>
                                        </div>
                                        <div className='media-header-item'>
                                            <div className='info-btn' onClick={() => { onSpam(); }} >
                                                {location.pathname !== '/email/draft' && <SvgSpam className='icon icon-info' color="#898989" />}
                                            </div>
                                        </div>
                                    </>
                                }
                                {/* <div className='media-header-item d-none'>
                                    <Dropdown className='dropdown-icon'>
                                        <Dropdown.Toggle variant="transparent" className='btn-link link-gray px-0'>
                                            <SvgThreeDot className='icon icon-three-dot' color="#898989" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className='ms-1' >
                                            {['Add task', 'Link conversation to lead', 'Link conversation to customer', 'Convert to lead', 'Convert to Customer'].map((i) =>
                                                <Dropdown.Item key={i}>{i}</Dropdown.Item>
                                            )}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div> */}
                            </>
                        }

                        {location.pathname === "/email/spam" &&
                            <>
                                <div className='media-header-item'>
                                    <div className='check-box' ></div>
                                    <Dropdown as={ButtonGroup} className="dropdown-checkbox">
                                        {/* <button className="btn btn-link px-0">
                                            <div className="form-check custom-checkbox mb-0">
                                                <input className="form-check-input selected" type="checkbox" value="" />
                                                <label className="form-check-label"></label>
                                            </div>
                                        </button> */}
                                        <Dropdown.Toggle variant="transparent" className='btn-link link-gray px-0' />
                                        <Dropdown.Menu className='dropdown-menu'>
                                            {['All', 'None', 'Read', 'Unread'].map((item) =>
                                                <Dropdown.Item key={item} onClick={() => onSelectMails(item)}>{item}</Dropdown.Item>
                                            )}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <div className='media-header-item px-0'>
                                    <span className="divider-line"></span>
                                </div>
                                {selectedMsgCount > 0 &&
                                    <>
                                        <div className='media-header-item px-0'>
                                            <div className="btn btn-link link-black f-14" onClick={() => onDelete()}>Delete forever</div>
                                        </div>
                                        <div className="btn btn-link link-black f-14" onClick={() => onNotSpam()}>Not spam</div>
                                    </>
                                }
                            </>
                        }

                        {location.pathname === "/email/trash" &&
                            <>
                                <div className='media-header-item'>
                                    <div className='check-box' ></div>
                                    <Dropdown as={ButtonGroup} className="dropdown-checkbox">
                                        {/* <button className="btn btn-link px-0">
                                            <div className="form-check custom-checkbox mb-0">
                                                <input className="form-check-input selected" type="checkbox" value="" />
                                                <label className="form-check-label"></label>
                                            </div>
                                        </button> */}
                                        <Dropdown.Toggle variant="transparent" className='btn-link link-gray px-0' />
                                        <Dropdown.Menu className='dropdown-menu'>
                                            {['All', 'None', 'Read', 'Unread'].map((item) =>
                                                <Dropdown.Item key={item} onClick={() => onSelectMails(item)}>{item}</Dropdown.Item>
                                            )}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <div className='media-header-item px-0'>
                                    <span className="divider-line"></span>
                                </div>
                                {selectedMsgCount > 0 &&
                                    <>
                                        <div className='media-header-item'>
                                            <div className='info-btn' onClick={() => { onSpam() }} >
                                                <SvgSpam className='icon icon-info' color="#898989" />
                                            </div>
                                        </div>
                                        <div className='media-header-item px-0'>
                                            <div className="btn btn-link link-black f-14" onClick={() => { onDelete(); }}>Delete forever</div>
                                        </div>
                                    </>
                                }
                            </>
                        }
                    </>
                    :
                    <strong className='title'>Email Settings</strong>
                }
            </div>

            <div className="media-header-right">
                {location.pathname !== "/email/settings" ?
                    <div className='media-header-item'>
                        <div className={!isSearching ? 'search-box' : 'search-box isShowResult'}>
                            <div className="lookup lookup-focus lookup-right">
                                <input
                                    id="search-input"
                                    type="text"
                                    className='form-control form-control-lg isLookupExpand'
                                    placeholder="Search"
                                    value={keyword}
                                    onFocus={() => setSearching(true)}
                                    onChange={(event) => { setKeyword(event.target.value); setSearching(true); }}
                                    onKeyDown={onKeyPressSearch}
                                />
                                {isSearching ?
                                    <div onClick={() => { setKeyword(''); setSearching(false); }}>
                                        <SvgClose className='icon-close' color="#000000" />
                                    </div>
                                    : null
                                }
                            </div>
                            <div id='searchBox' className='search-result' style={{ zIndex: 15 }}>
                                {!isAdvancedSearching ?
                                    <div className='search-list' onClick={() => { document.getElementById("search-input").focus() }}>
                                        <div className='search-header'>
                                            <div className='list-tag'>
                                                <div className='tag-item'>
                                                    <span className='tag' onClick={() => setHasAttachment(!hasAttachment)}>
                                                        {hasAttachment ? <SvgCheck className='icon-check' color="#000000" /> : null}
                                                        Contains attachment
                                                    </span>
                                                </div>
                                                <div className='tag-item'>
                                                    <span className='tag' onClick={() => setFromMe(!isFromMe)}>
                                                        {isFromMe ? <SvgCheck className='icon-check' color="#000000" /> : null}
                                                        From me
                                                    </span>
                                                </div>
                                                <div className='tag-item'>
                                                    <span className='tag' onClick={() => setLast7Days(!last7Days)}>
                                                        {last7Days ? <SvgCheck className='icon-check' color="#000000" /> : null}
                                                        Last 7 Days
                                                    </span>
                                                </div>
                                            </div>
                                            <button type="button" className="open-filter" onClick={() => { setAdvancedSearching(!isAdvancedSearching) }}>
                                                <SvgFilter className='icon-filter' color='#5F6267' />
                                            </button>
                                        </div>
                                        <div className='search-body'>
                                            <div className='media-list media-list-divided media-search'>
                                                <div className='media-list-body'>
                                                    {sugEmailsList.length > 0 &&
                                                        sugEmailsList.map((item, index) => {
                                                            let userEmail = '';
                                                            let userName = '';
                                                            if (location.pathname === "/email/draft" || location.pathname === "/email/search") {
                                                                userEmail = item.from[0].email;
                                                                userName = item.from[0].name;
                                                            } else {
                                                                userEmail = item.from.email;
                                                                userName = item.from.name;
                                                            }
                                                            return item && index < 2 && (
                                                                <div key={`account-${item.thread_id}`} className='media' onClick={() => { navigate(`/email/thread/+${item.thread_id}`) }}>
                                                                    <div className="media-left">
                                                                        <span className='avatar avatar-md avatar-alphabet'>{!!userEmail ? userEmail[0].toUpperCase() : "E"}</span>
                                                                    </div>
                                                                    <div className="media-body">
                                                                        <b className='f-14'>{userName}</b>
                                                                        <div className='flexbox align-items-center'>
                                                                            <p className="text-truncate f-14">{userEmail}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    {sugEmailsList.length > 0 && sugEmailsList.map((item, index) => {
                                                        return item && index < 3 && (
                                                            <div key={`message-${item.thread_id}`} className='media' onClick={() => { navigate(`/email/thread/+${item.thread_id}`) }}>
                                                                <div className="media-left"><span className='avatar avatar-md h-30'><i className='icon-email f-20'></i></span></div>
                                                                <div className="media-body">
                                                                    <div className='flexbox align-items-center'>
                                                                        <p className='text-truncate'>{item.snippet}</p>
                                                                        <small>{formatDate(item.date_time)}</small>
                                                                    </div>
                                                                    <b>{item.from.name}, {item.to[0].name === userEmail ? "me" : item.to[0].name}</b>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}

                                                    {(keyword || hasAttachment || isFromMe || last7Days) ?
                                                        <div className='media' onClick={() => onSearch()} style={{ borderBottom: "none" }}>
                                                            <div className="media-left"><span className='avatar avatar-md h-30'><i className='icon-search f-20'></i></span></div>
                                                            <div className="media-body">
                                                                <b>All results for "{keyword}"</b>
                                                            </div>
                                                            <p style={{ color: "#AAAAAA" }}>Press Enter</p>
                                                        </div> : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                                }
                                {isAdvancedSearching ?
                                    <div className='search-filter'>
                                        <div className='search-header border-0'>
                                            <h3>Advanced search  filters</h3>
                                            <button
                                                type="button"
                                                className="close-filter"
                                                onClick={() => { setAdvancedSearching(false); setSearching(false); }}
                                            >
                                                {/* <i className='icon-close'></i> */}
                                                <SvgClose className='icon-close' color='#000000' />
                                            </button>
                                        </div>
                                        <div className='search-body'>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group ">
                                                        <label className="form-label">Folder </label>
                                                        <select className="form-select" onChange={(event) => { setFolder(event.target.value); }} value={folder}>
                                                            <option defaultValue value="All">All Folders</option>
                                                            {
                                                                state.emailMenuItems.map((value) => (
                                                                    <option value={value} key={value}>{value}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group ">
                                                        <label className="form-label">Date </label>
                                                        <select className="form-select" onChange={(event) => {
                                                            setDt(event.target.value);
                                                        }} value={dt}>
                                                            <option defaultValue value="All">Anydate</option>
                                                            {
                                                                state.filterDateItems.map((item) => (
                                                                    <option value={item.key} key={item.key}>{item.text}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group ">
                                                        <label className="form-label">Custom Range </label>
                                                        <div className="input-icon input-icon-right">
                                                            <span className='icon'>
                                                                <SvgCalendar className="icon-calendar" color='#5F6267' />
                                                            </span>
                                                            <input type="date" className="form-control" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group ">
                                                        <label className="form-label">Attachments </label>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="attachment" id="allAtt" value="All" onChange={onAttachmentChange}
                                                                checked={attachment === "All"} />
                                                            <label className="form-check-label" htmlFor="allAtt">All</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="attachment" id="withoutAtt" value="without" onChange={onAttachmentChange}
                                                                checked={attachment === "without"} />
                                                            <label className="form-check-label" htmlFor="withoutAtt">Without Attachments</label>
                                                        </div>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="radio" name="attachment" id="withAtt" value="with" onChange={onAttachmentChange}
                                                                checked={attachment === "with"} />
                                                            <label className="form-check-label" htmlFor="withAtt">With Attachments</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-12'>
                                                    <div className="btn-toolbar justify-content-end">
                                                        <button className="btn btn-lg btn-link px-0" onClick={() => {
                                                            setAdvancedSearching(false);
                                                            setSearching(false);
                                                        }}>Cancel</button>
                                                        <button className="btn btn-lg btn-secondary" onClick={onSearch}>Search</button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                    : null
                }

                <div className='media-header-item px-0'><span className="divider-line"></span></div>

                {props.pageIndex && location.pathname !== "/email/settings" ?
                    <div className='media-header-item'>
                        {(state.emailAvailableMenus[props.box] && state[props.box]?.emails?.length > 0) &&
                            <nav className="pagination-nav">
                                <span className="pagination-count">
                                    {state.pageSize * (props.pageIndex - 1) + 1}-{(totalMsgCountData[props.box] >= state.pageSize * props.pageIndex) ? state.pageSize * props.pageIndex : totalMsgCountData[props.box]} of {totalMsgCountData[props.box]}
                                </span>
                                <ul className="pagination">
                                    <li className="page-item">
                                        <div className="page-link page-prev" onClick={() => props.onPrevPage()}>
                                            <SvgArrowLeft className='icon-arrow-left' color="#5F6267" opacity={props.pageIndex === 1 ? "0.3" : "1"} />
                                        </div>
                                    </li>
                                    <li className="page-item">
                                        <div className="page-link page-next" onClick={() => props.onNextPage()}>
                                            <SvgArrowRight className='icon-arrow-right' color="#5F6267" opacity={totalMsgCountData[props.box] >= state.pageSize * props.pageIndex ? "1" : "0.3"} />
                                        </div>
                                    </li>
                                </ul>
                            </nav>
                        }
                        {(location.pathname === "/email/search" && state[props.box].emails?.length > 0) &&
                            <nav className="pagination-nav">
                                <span className="pagination-count">
                                    {state.pageSize * (props.pageIndex - 1) + 1}-{(totalMsgCountData[props.box] >= state.pageSize * props.pageIndex) ? state.pageSize * props.pageIndex : totalMsgCountData[props.box]} of {totalMsgCountData[props.box]}
                                </span>
                                <ul className="pagination">
                                    <li className="page-item">
                                        <div className="page-link page-prev" onClick={() => props.onPrevPage()}>
                                            <SvgArrowLeft className='icon-arrow-left' color="#5F6267" opacity={props.pageIndex === 1 ? "0.3" : "1"} />
                                        </div>
                                    </li>
                                    <li className="page-item">
                                        <div className="page-link page-next" onClick={() => props.onNextPage()}>
                                            <SvgArrowRight className='icon-arrow-right' color="#5F6267" opacity={state[props.box].emails.length >= state.pageSize * props.pageIndex ? "1" : "0.3"} />
                                        </div>
                                    </li>
                                </ul>
                            </nav>
                        }
                    </div>
                    : null
                }

                <div className='media-header-item'>
                    <Link className="btn btn-link link-gray px-0" to="/email/settings">
                        <SvgSettings className='icon icon-settings' color={location.pathname !== "/email/settings" ? "#898989" : "#F07D12"} />
                    </Link>
                </div>
            </div>

            <DeleteConfirmModal onModalOk={() => onModalOk()} />
        </div>
    );
}

export default EmailTopbar;