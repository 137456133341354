import React from 'react';
import EmailTopbar from '../EmailTopbar/EmailTopbar'

const Outbox = () => {
  return (
    <div className="section-content">
      <div className="media-list media-email">
        <EmailTopbar />
        <div className="media-list-body">
          <div className='media-title-header'><strong className='title'>Outbox</strong></div>          
          <div className="media media-outbox outbox">            
            <div className="media-body media-body text-center py-50">
                <span className="media-msz f-20">Comming Soon...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Outbox;