import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import EmailTopbar from '../EmailTopbar/EmailTopbar'

const Scheduled = () => {
    return (
        <div className="section-content">
            <div className="media-list media-list-divided media-list-hover media-email">
                <EmailTopbar />
                <div className="media-list-body">
                    <div className='media-title-header'><strong className='title'>Scheduled </strong></div>
                    {[1,2,3,4,5].map((i) => 
                        <div className="media media-schedule d-block" key={i}>
                            <div className="media-body">
                                <div className='row justify-content-between'>
                                    <div className='col-auto'>
                                        <div className="form-check custom-checkbox mb-5">
                                            <input className="form-check-input" type="checkbox" value="" id={i} />
                                            <label className="form-check-label f-13" htmlFor={i}>To: Smashbox Cosmetics</label>
                                        </div>
                                        <div className='media-mail text-truncate'>&lt; anahit@senseisrm.com &gt;</div>
                                    </div>
                                    <div className='col-8'>
                                        <strong className='media-subject d-block mb-5'>Off Bestsellers</strong>
                                        <div className='flexbox'>                                        
                                            <div className="media-msz text-truncate">Use code SUCCESS40 at checkout to save 40% now! Take a little more time and a little extra Use code SUCCESS40 at checkout to save 40% now! Take a little more time and a little extra Use code SUCCESS40 at checkout to save 40% now! Take a little more time and a little extra ...</div>
                                            <span className="media-file"><i className="icon-file"></i></span>
                                        </div>
                                    </div>
                                    <div className='col-auto'>
                                        <div className='media-option'>
                                            <div className='media-option-item'>
                                                <small className="media-dayTime">23 Sep</small>
                                            </div>
                                            <div className='media-option-item'>
                                                <Dropdown className='dropdown-icon dropdown-reply'>
                                                    <Dropdown.Toggle variant="link" className='btn link-gray px-0'><i className="icon-ver-menu"></i></Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item href="/#"><i className='icon icon-schedule-cancel'></i><span>Cancel Schedule</span></Dropdown.Item>
                                                        <Dropdown.Item href="/#"><i className='icon icon-delete f-18'></i><span>Delete</span></Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                </div>
            </div>
        </div>
    );
}


export default Scheduled;