import React, { useEffect } from 'react'

import { SvgToastClose, SvgToastInfo } from '../../utils/IconInfo'

import './Toast.css'

const Toast = ({ isShowToast, setIsShowToast }) => {

  useEffect(() => {
    if (isShowToast) {
      const timer = setTimeout(() => {
        setIsShowToast(false);
      }, 3500);
      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowToast])

  return isShowToast && (
    <div className='notification-container top-left'>
      <div>
        <SvgToastInfo />
      </div>
      <div className="notification-message"><p>Email Account Required. Please Add Your Email Address to Begin Email Communication.</p></div>
      <div className='notification-close-btn' onClick={() => setIsShowToast(false)}>
        <SvgToastClose />
      </div>
    </div>
  )
}

export default Toast