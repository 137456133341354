import React from 'react';
import LeftPanel from './LeftPanel/LeftPanel';
import Email from './Email/Email';

const Components = () => {
  return (
    <div className='whole-container'>
      <LeftPanel />
      <Email />
    </div>
  );
}

export default Components;