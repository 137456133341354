import React, { useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";

import { getGoogleMenuItems } from "../../../api/index.js";
import { setMenuItems, setEmailProvider, setAccountId, setEmailBoxMenuLoaded, setCurrentEmailBox, setIsSelectMenuItem } from "../../../action/index.js";

const EmailSidebar = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const [loadingAccounts, setLoadingAccounts] = useState(false);
    const [loadingMenu, setLoadingMenu] = useState(false);

    const emailProvider = useSelector((state) => state.reducer.emailProvider);
    const accountsList = useSelector((state) => state.reducer.emailsList)
    const emailAvailableMenus = useSelector((state) => state.reducer.emailAvailableMenus);
    const accountId = useSelector((state) => state.reducer.account_id)
    const totalMsgCountData = useSelector((state) => state.reducer.totalMsgCountData);
    const unReadMsgCountData = useSelector((state) => state.reducer.unReadMsgCountData);

    const onEmailProviderChange = (event) => {
        dispatch(setEmailProvider(emailProvider));
        dispatch(setAccountId(event.target.value));
    };

    useEffect(() => {
        if (accountId === 0) {
            setLoadingAccounts(true);
        } else {
            setLoadingAccounts(false)
        }
    }, [accountId])

    useEffect(() => {
        if (emailProvider === null)
            return;
        const fetchData = async () => {
            try {
                dispatch(setEmailBoxMenuLoaded(false));
                setLoadingMenu(true);
                if (accountId !== 0) {
                    const menuItems = await getGoogleMenuItems(accountId);
                    dispatch(setMenuItems(menuItems));
                }
            } catch (err) {
                console.error(err);
            } finally {
                setLoadingMenu(false);
                dispatch(setEmailBoxMenuLoaded(true));
            }
        };

        fetchData().catch(console.error);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emailProvider, accountId]);

    return (
        <aside className="aside">
            <div className="aside-header">
                <span className="aside-title">Mail</span>
            </div>
            <div className="aside-body">
                <div className="form-group ">
                    {loadingAccounts ? <BeatLoader color="#36d7b7" /> :
                        <select className="form-select" onChange={onEmailProviderChange} defaultValue={accountId}>
                            {accountsList.accounts.map((item) =>
                                <option key={item.account_id} value={item.account_id}>{item.email}</option>
                            )}
                        </select>
                    }
                </div>
                <div className="form-group-lg">
                    <Link to="/email/compose" className="btn btn-primary w-100">
                        <span className="icon"><i className="icon icon-plus"></i></span>
                        <span className="text" style={{ fontWeight: 500 }}>Compose email</span>
                    </Link>
                </div>
                {loadingMenu ? <BeatLoader color="#36d7b7" /> :
                    <ul className="nav nav-pills flex-column">
                        {emailAvailableMenus.inbox !== null ?
                            <li className={location.pathname === "/email/inbox" ? "nav-item active" : "nav-item"}>
                                <Link to="/email/inbox" className="nav-link" onClick={() => { dispatch(setCurrentEmailBox('inbox')); dispatch(setIsSelectMenuItem(true)); }}>Inbox</Link>
                                {unReadMsgCountData.inbox > 0 ?
                                    <span className="badge text-dark">{unReadMsgCountData.inbox}</span>
                                    :
                                    null
                                }
                            </li>
                            :
                            null
                        }
                        {emailAvailableMenus.sent !== null ?
                            <li className={location.pathname === "/email/sent" ? "nav-item active" : "nav-item"}>
                                <Link to="/email/sent" className="nav-link" onClick={() => { dispatch(setCurrentEmailBox('sent')); dispatch(setIsSelectMenuItem(true)); }}>Sent</Link>
                            </li>
                            :
                            null
                        }
                        {emailAvailableMenus.outbox !== null ?
                            <li className={location.pathname === "/email/outbox" ? "nav-item active" : "nav-item"}>
                                <Link to="/email/outbox" className="nav-link" onClick={() => { dispatch(setCurrentEmailBox('outbox')); dispatch(setIsSelectMenuItem(true)); }}>Outbox</Link></li>
                            :
                            null
                        }
                        {emailAvailableMenus.scheduled !== null ?
                            <li className={location.pathname === "/email/scheduled" ? "nav-item active" : "nav-item"}>
                                <Link to="/email/scheduled" className="nav-link" onClick={() => { dispatch(setCurrentEmailBox('scheduled')); dispatch(setIsSelectMenuItem(true)); }}>Scheduled</Link>
                                <span className="badge text-dark">1</span>
                            </li>
                            :
                            null
                        }
                        {emailAvailableMenus.draft !== null ?
                            <li className={location.pathname === "/email/draft" ? "nav-item active" : "nav-item"}>
                                <Link to="/email/draft" className="nav-link" onClick={() => { dispatch(setCurrentEmailBox('draft')); dispatch(setIsSelectMenuItem(true)); }}>Drafts</Link>
                                {totalMsgCountData.draft > 0 ?
                                    <span className="badge text-dark">{totalMsgCountData.draft}</span>
                                    :
                                    null
                                }
                            </li>
                            :
                            null
                        }
                        {emailAvailableMenus.spam !== null ?
                            <li className={location.pathname === "/email/spam" ? "nav-item active" : "nav-item"}>
                                <Link to="/email/spam" className="nav-link" onClick={() => { dispatch(setCurrentEmailBox('spam')); dispatch(setIsSelectMenuItem(true)); }}>Spam</Link>
                                {unReadMsgCountData.spam > 0 ?
                                    <span className="badge text-dark">{unReadMsgCountData.spam}</span>
                                    :
                                    null
                                }
                            </li>
                            :
                            null
                        }
                        {emailAvailableMenus.trash !== null ?
                            <li className={location.pathname === "/email/trash" ? "nav-item active" : "nav-item"}>
                                <Link to="/email/trash" className="nav-link" onClick={() => { dispatch(setCurrentEmailBox('trash')); dispatch(setIsSelectMenuItem(true)); }}>Trash</Link>
                            </li>
                            :
                            null
                        }
                    </ul>
                }
                {/* <hr /> */}
                {/* <ul className="nav nav-pills flex-column d-none">
                    <li className={location.pathname === "/email/templates" ? "nav-item active" : "nav-item"}>
                        <Link to="/email/templates" className="nav-link" onClick={() => { dispatch(setCurrentEmailBox('templates')); dispatch(setIsSelectMenuItem(true)); }}>Templates</Link></li>
                </ul> */}
            </div>
        </aside>
    );
}

export default EmailSidebar;