import React from 'react';
import { useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';

import { getBlobFromUrl } from "../../../api/index.js";
import { SvgAttachmentFile, SvgDelete, SvgDownload } from '../../../utils/IconInfo.js';
import { useState } from 'react';
import { useLocation } from 'react-router';

const convertFileSize = (size) => {
    if (size < 1024) return `${Math.round(size)} byte`
    else if (size < Math.pow(1024, 2)) return `${Math.round(size / 1024)} KB`
    else return `${Math.round(size / Math.pow(1024, 2))} MB`
}

const MediaFiles = (props) => {
    const location = useLocation();
    const [selectedFileIndex, setSelectedFileIndex] = useState(null)
    const accountId = useSelector((state) => state.reducer.account_id);

    const handleDownload = async (attachment, index) => {
        setSelectedFileIndex(index);

        const blob = await getBlobFromUrl(accountId, attachment.download_url);
        setSelectedFileIndex(null);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        var url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = attachment.name;
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    }

    return (
        <div className='list-group-media'>
            {props.attachments.map((attachment, index) =>
                <div className='list-group-item' key={index}>
                    <div className="media media-files">
                        <div className="media-icon">
                            <SvgAttachmentFile className='icon icon-attachment' color='#5F6267' />
                            <span className='file-type'>{attachment.contentType}</span>
                        </div>
                        <div className="media-body">
                            <div className='file-name text-truncate'>{attachment.name}</div>
                            <span className="file-size">{convertFileSize(attachment.size)}</span>
                        </div>

                        <div className='media-action'>
                            {location.pathname.includes('thread') && !props.messageId.includes('r') ?
                                <>
                                    {index !== selectedFileIndex &&
                                        <div className='action' onClick={() => handleDownload(attachment, index)}>
                                            <SvgDownload className='icon icon-download' color='#5F6267' />
                                        </div>
                                    }
                                </>
                                :
                                <div className='media-action'>
                                    <div className='media-header-item delete-btn' onClick={() => { props.onRemoveFile(index) }} >
                                        <SvgDelete className='icon icon-delete' color='#5F6267' />
                                    </div>
                                </div>
                            }
                            <ClipLoader
                                color={'#5F6267'}
                                cssOverride={{
                                    display: 'block',
                                    margin: 'auto auto',
                                }}
                                size={15}
                                loading={index === selectedFileIndex ? true : false}
                                speedMultiplier={0.5}
                                aria-label='Loading Spinner'
                                data-testid='loader'
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>

    );
}


export default MediaFiles;