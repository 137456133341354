export const testclick = (key) => {
    return (dispatch) => {
        dispatch({
            type: "testclick",
            value: key,

        })
    }
}

export const setCurrentEmailBox = (value) => {
    return (dispatch) => {
        dispatch({
            type: 'setCurrentEmailBox',
            payload: value,
        })
    }
}

export const setEmailBoxMenuLoaded = (value) => {
    return (dispatch) => {
        dispatch({
            type: "setEmailBoxMenuLoaded",
            payload: value,
        })
    }
}

export const handleCommonModal = (key) => {
    return (dispatch) => {
        dispatch({
            type: "handleCommonModal",
            key: key
        })
    }
}

export const handleCloseModal = (key) => {
    return (dispatch) => {
        dispatch({
            type: "handleCloseModal",
            key: key
        })
    }
}

export const sidebarExpanded = (key) => {
    return (dispatch) => {
        dispatch({
            type: "sidebarExpanded",
            key: key
        })
    }
}

export const integrationStep = (key) => {
    return (dispatch) => {
        dispatch({
            type: "integrationStep",
            key: key

        })
    }
}

export const setAccountId = (value) => {
    return (dispatch) => {
        dispatch({
            type: "setAccountId",
            payload: value
        })
    }
}

export const setEmails = (key, emails, pageIndex) => {
    return (dispatch) => {
        dispatch({
            type: "setEmails",
            key: key,
            emails: emails,
            pageIndex: pageIndex
        })
    }
}

export const setMenuItems = (menuItems) => {
    return (dispatch) => {
        dispatch({
            type: "setMenuItems",
            menuItems: menuItems
        })
    }
}

export const setEmailsList = (emailsList) => {
    return (dispatch) => {
        dispatch({
            type: "setEmailsList",
            emailsList: emailsList
        })
    }
}

export const onSelectChanged = (event, box, thread_id) => {
    return (dispatch) => {
        dispatch({
            type: "onSelectChanged",
            event: event,
            box: box,
            thread_id: thread_id
        })
    }
}

export const selectEmails = (box, opt) => {
    return (dispatch) => {
        dispatch({
            type: "selectEmails",
            box: box,
            opt: opt
        })
    }
}

export const setEmailProvider = (provider) => {
    return (dispatch) => {
        dispatch({
            type: "setEmailProvider",
            provider: provider
        })
    }
}

export const setEmailSettingData = (value) => {
    return (dispatch) => {
        dispatch({
            type: "setEmailSettingData",
            payload: value
        })
    }
}

export const setSelectedMsgCount = () => {
    return (dispatch) => {
        dispatch({
            type: "setSelectedMsgCount",
            payload: 0
        })
    }
}

export const setSelectedUnreadMsgCount = () => {
    return (dispatch) => {
        dispatch({
            type: "setSelectedUnreadMsgCount",
            payload: 0
        })
    }
}

export const setUnreadMsgCoundData = (value) => {
    return (dispatch) => {
        dispatch({
            type: "setUnreadMsgCoundData",
            payload: value
        })
    }
}

export const setTotalMsgCountData = (value) => {
    return (dispatch) => {
        dispatch({
            type: "setTotalMsgCountData",
            payload: value
        })
    }
}

export const setIsDeleteConfirmModal = (value) => {
    return (dispatch) => {
        dispatch({
            type: "setIsDeleteConfirmModal",
            payload: value
        })
    }
}

export const setIsSelectMenuItem = (value) => {
    return (dispatch) => {
        dispatch({
            type: 'setIsSelectMenuItem',
            payload: value
        })
    }
}