import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";
import LoadingOverlay from 'react-loading-overlay-ts';
import { useNavigate } from 'react-router-dom';

import { getGoogleThreads, batchTrashEmail, batchMarkAsSpam } from "../../../api/index.js";
import { formatDate } from "../../../api/helper.js";
import { setEmails, onSelectChanged, setCurrentEmailBox, setIsSelectMenuItem } from "../../../action/index.js";

import EmailTopbar from '../EmailTopbar/EmailTopbar'
import { SvgAttachment } from '../../../utils/IconInfo.js';

const Inbox = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [pageIndex, setPageIndex] = useState(1);
  const [loading, setLoading] = useState(true);

  const isSelectMenuItem = useSelector((state) => state.reducer.isSelectMenuItem);
  const props = useSelector((state) => state.reducer);
  const emailAvailableMenus = useSelector((state) => state.reducer.emailAvailableMenus);
  const pageTokens = useSelector((state) => state.reducer.pageTokens);
  const accountId = useSelector((state) => state.reducer.account_id);
  const emailBoxMenuLoaded = useSelector((state) => state.reducer.emailBoxMenuLoaded);

  const fetchData = async () => {
    try {
      setLoading(true);
      const googleThreadsResponse = await getGoogleThreads(emailAvailableMenus.inbox.id, pageTokens.inbox[pageIndex], accountId);
      dispatch(setEmails('inbox', googleThreadsResponse, pageIndex));
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  const onRefresh = () => {
    fetchData();
  }
  const onDelete = async () => {
    let emails = props.inbox.emails;
    let threadIDs = [];
    emails.map(async (email) => {
      if (email.selected) {
        threadIDs.push(email.thread_id);
      }
    });
    if (threadIDs.length > 0) {
      try {
        dispatch(setEmails('inbox', { emails: emails.filter((email) => !threadIDs.includes(email.thread_id)) }))
        await batchTrashEmail(threadIDs, accountId);
      } catch (err) {
        // error handling
      }
    }
  }
  const onSpam = async () => {
    let emails = props.inbox.emails;
    let threadIDs = [];
    emails.map(async (email) => {
      if (email.selected) {
        threadIDs.push(email.thread_id);
      }
    });
    if (threadIDs.length > 0) {
      try {
        dispatch(setEmails('inbox', { emails: emails.filter((email) => !threadIDs.includes(email.thread_id)) }))
        await batchMarkAsSpam(threadIDs, accountId);
      } catch (err) {
        // console.error(err);
      }
    }
  }
  const onPrevPage = () => {
    if (pageIndex > 1) {
      setPageIndex(pageIndex - 1);
    }
  }
  const onNextPage = () => {
    if (pageTokens.inbox[pageIndex + 1]) {
      setPageIndex(pageIndex + 1);
    }
  }

  useEffect(() => {
    dispatch(setCurrentEmailBox('inbox'))
  }, [])

  useEffect(() => {
    if (isSelectMenuItem) {
      setPageIndex(1);
    }
  }, [isSelectMenuItem])
  

  useEffect(() => {
    setLoading(true)
    if (accountId !== 0 && emailAvailableMenus.inbox !== null) {
      fetchData();
      dispatch(setIsSelectMenuItem(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailAvailableMenus.inbox, pageIndex]);

  return (
    <LoadingOverlay active={loading || !emailBoxMenuLoaded} spinner text='Loading ...' >
      <div className="section-content">
        <div className="media-list media-list-divided media-list-hover media-email">
          <EmailTopbar onRefresh={() => onRefresh()} onDelete={() => onDelete()} onSpam={() => onSpam()} box="inbox" pageIndex={pageIndex} onNextPage={() => onNextPage()} onPrevPage={() => onPrevPage()} />
          <div className="media-list-body content-body scrollbar">
            <div className='media-title-header'><strong className='title'>Inbox</strong></div>
            {loading || !emailBoxMenuLoaded ?
              <div className="media media-inbox">
                <BeatLoader color="#36d7b7" />
              </div>
              :
              ((props.inbox.emails.length === 0 && !loading) ?
                <div className="media media-inbox">
                  There is no email in this box.
                </div>
                :
                props.inbox.emails.map((thread, index) => (
                  <div className="media media-inbox" key={thread.thread_id}>
                    <div className="form-check custom-checkbox title-part">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={thread.selected ? true : false}
                        id={thread.thread_id}
                        onChange={(event) => dispatch(onSelectChanged(event, 'inbox', thread.thread_id))}
                      />
                      <div className="form-check-label title-text-truncate" htmlFor={thread.thread_id}>
                        <div className={thread.is_read ? null : "unread-part"}>{thread.from.name}</div>
                      </div>
                    </div>
                    <div className="content-data media-body" onClick={() => { navigate(`/email/thread/+${thread.thread_id}`); }} >
                      <div className='media-subject content-title'>
                        <div className={`text-truncate ${thread.is_read ? null : "unread-part"}`}>{thread.subject}</div>
                      </div>
                      <p className='text-truncate m-auto content-text ps-10'>{thread.snippet}</p>
                    </div>
                    <div className='media-info'>
                      {thread.has_attachments && <span className="media-file"><SvgAttachment className='icon-download' width="12px" height='12px' color='#5F6267' /></span>}
                      <small className="media-dayTime">{formatDate(thread.date_time)}</small>
                    </div>
                  </div>
                )
                ))
            }
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
}

export default Inbox;