import React, { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { useDispatch, useSelector } from "react-redux";
import { handleCloseModal } from "../../action/index.js";
import MediaFiles from '../Email/MediaFiles/MediaFiles';
import Mention from '../../assets/images/icons/mention.svg';
import NewDocument from '../../assets/images/icons/new-doc.svg';
import Attachment from '../../assets/images/icons/attachment.svg';

const AddTaskModal = () => {

    const dispatch = useDispatch();
    const props = useSelector((state) => state.reducer);

    const [isTaskRecurrence, setIsTaskRecurrence] = useState(false);

    return (
        <Offcanvas show={props.isShowOffcanvasModal} onHide={(e) => { dispatch(handleCloseModal()) }} backdrop="static" placement='end' className='offcanvas-default bg-white'>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Add Task</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="pt-0 scrollbar">
                <div className="row">
                    <div className="col">
                        <div className='task-form border-0'>
                            <div className='task-form-inner'>
                                <div className="form-check mb-20">
                                    <input className="form-check-input" type="checkbox" value="" id='highPriority' />
                                    <label className="form-check-label" htmlFor='highPriority'>High priority</label>
                                </div>
                                <div className='form-group-md'>
                                    <label className="form-label">Title <span className='asterisk'>*</span></label>
                                    <input type="text" className="form-control" placeholder="Enter title of task" />
                                </div>

                                <div className='form-group-md mb-0'>
                                    <label className="form-label">Description</label>
                                    <textarea className="form-control" rows={4} placeholder="Start typing the details about the task..." />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className='task-form bg-whitesmoke-dark p-15'>
                            <div className='task-form-inner'>
                                <div className="form-group-md">
                                    <label className="form-label">Responsible person</label>
                                    <select className="form-select">
                                        <option>Anahit Pearce</option>
                                        <option value="1">Arshak Ghazaryan</option>
                                        <option value="2">Valery Herasimchyk</option>
                                        <option value="3">Aleq Baghumyan</option>
                                    </select>
                                </div>
                                <div className="form-group-md ">
                                    <label className="form-label">Related to</label>
                                    <select className="form-select">
                                        <option>Link this task</option>
                                        <option value="1">Customer</option>
                                        <option value="2">Lead</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex">
                    <div className='list-button'>
                        <div className='list-item-button'>
                            <a href='/#' className="btn btn-link px-0">
                                <div className='me-6'>
                                    <img alt='attachment' src={Attachment} />
                                </div>
                                <span className='text'>Flie</span>
                            </a>
                        </div>
                    </div>
                    <div className='mx-10 my-auto h-30 vr'></div>
                    <div className='list-button'>
                        <div className='list-item-button'>
                            <a href='/#' className="btn btn-link px-0">
                                <div className='me-6'>
                                    <img alt='new-doc' src={NewDocument} />
                                </div>
                                <span className='text'>New document</span>
                            </a>
                        </div>
                    </div>
                    <div className='mx-10 my-auto h-30 vr'></div>
                    <div className='list-button'>
                        <div className='list-item-button'>
                            <a href='/#' className="btn btn-link px-0">
                                <div className='me-6'>
                                    <img alt='mention' src={Mention} />
                                </div>
                                <span className='text'>Mention</span>
                            </a>
                        </div>
                    </div>
                    <div className='mx-10 my-auto h-30 vr'></div>
                    <div className='list-button'>
                        <div className='list-item-button'>
                            <a href='/#' className="btn btn-link px-0">
                                <span className='text'>Checklist</span>
                            </a>
                        </div>
                    </div>
                    {/* <MediaFiles /> */}
                </div>
                <div className="row">
                    <div className="col-3">
                        <div className='form-group-lg'>
                            <label className="form-label">Task type</label>
                            <input type="text" className="form-control" placeholder="Select a type" />
                        </div>
                    </div>
                    <div className="col-3">
                        <div className='form-group-lg'>
                            <label className="form-label">Due date <span className='asterisk'>*</span></label>
                            <div className="input-icon">
                                <span className='icon'><i className="icon-calendar"></i></span>
                                <input type="date" className="form-control" placeholder="07/19/2022" />
                            </div>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className='form-group-lg'>
                            <label className="form-label">Time</label>
                            <div className="input-icon">
                                <span className='icon'><i className="icon-clock"></i></span>
                                <input type="time" className="form-control" placeholder="15:29" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className="col-12">
                        <div className="form-check form-switch form-check-reverse d-inline-block">
                            <label className="form-check-label form-label" htmlFor="taskRecurrence">Task Recurrence</label>
                            <input className="form-check-input" onChange={() => { setIsTaskRecurrence(!isTaskRecurrence) }} type="checkbox" role="switch" id="taskRecurrence" />
                        </div>
                    </div>
                </div>
                {
                    isTaskRecurrence &&
                    <>
                        <div className='row'>
                            <div className="col-3">
                                <div className='form-group-lg'>
                                    <label className="form-label">Start Date</label>
                                    <div className="input-icon">
                                        <span className='icon'><i className="icon-calendar"></i></span>
                                        <input type="date" className="form-control" placeholder="08/30/2022" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className='form-group-lg'>
                                    <label className="form-label">End Date</label>
                                    <div className="input-icon">
                                        <span className='icon'><i className="icon-calendar"></i></span>
                                        <input type="date" className="form-control" placeholder="08/31/2022" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-12">
                                <div className='form-group'>
                                    <label className="form-label">Recurrence Pattern</label>
                                </div>
                                <Tab.Container defaultActiveKey="daily">
                                    <Nav className="nav-bordered nav-justified mxw-300">
                                        <Nav.Item><Nav.Link eventKey="daily">Daily</Nav.Link></Nav.Item>
                                        <Nav.Item><Nav.Link eventKey="weekly">Weekly</Nav.Link></Nav.Item>
                                        <Nav.Item><Nav.Link eventKey="monthly">Monthly</Nav.Link></Nav.Item>
                                    </Nav>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="daily">
                                            <ul className="list-group-check">
                                                <li className="list-group-check-item">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" value="" name="day" id="everyDay" />
                                                        <label className="form-check-label" htmlFor="everyDay">Every day</label>
                                                    </div>
                                                </li>
                                                <li className="list-group-check-item">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="radio" value="" name="day" id="everyWeekDay" />
                                                        <label className="form-check-label" htmlFor="everyWeekDay">Every week day</label>
                                                    </div>
                                                </li>
                                            </ul>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="weekly">
                                            <div className="form-group-md">
                                                <div className='row align-items-center'>
                                                    <div className='col-auto'>
                                                        <div className="form-check mb-0">
                                                            <input className="form-check-input" type="radio" value="" id="repeatEvery" />
                                                            <label className="form-check-label" htmlFor="repeatEvery">Repeat for every</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <input type="text" className="form-control mxw-50" />
                                                    </div>
                                                    <div className="col-auto">
                                                        <label className="col-form-label fw-normal">week (s)</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group-md">
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <ul className="list-group-inline">
                                                            {['Mon', 'Tue', 'Wed', 'Thurs', 'Sat', 'Fri', 'Sun'].map((i) =>
                                                                <li className="list-group-inline-item" key={i}>
                                                                    <div className="form-check">
                                                                        <input className="form-check-input" type="checkbox" value="" name="day" id={i} />
                                                                        <label className="form-check-label" htmlFor={i}>{i}</label>
                                                                    </div>
                                                                </li>
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="monthly">
                                            <div className="form-group-md">
                                                <div className='row align-items-center'>
                                                    <div className='col-auto'>
                                                        <div className="form-check mb-0">
                                                            <input className="form-check-input" type="radio" value="" id="oneDayMonthly" />
                                                            <label className="form-check-label" htmlFor="oneDayMonthly">One day</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <select className="form-select">
                                                            <option>1</option>
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-auto">
                                                        <label className="col-form-label fw-normal">Of every</label>
                                                    </div>
                                                    <div className="col-auto">
                                                        <input type="text" className="form-control mxw-50" />
                                                    </div>
                                                    <div className="col-auto">
                                                        <label className="col-form-label fw-normal">Month (s)</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group-md">
                                                <div className='row align-items-center'>
                                                    <div className='col-auto'>
                                                        <div className="form-check mb-0">
                                                            <input className="form-check-input" type="radio" value="" id="onMonthly" />
                                                            <label className="form-check-label" htmlFor="onMonthly">On</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-auto">
                                                        <select className="form-select">
                                                            <option>First</option>
                                                            <option value="1">Second</option>
                                                            <option value="2">Third</option>
                                                            <option value="3">Four</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-auto">
                                                        <select className="form-select">
                                                            <option>Monday</option>
                                                            <option value="1">Tuesday</option>
                                                            <option value="2">Wednesday</option>
                                                            <option value="3">Thursday</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-auto">
                                                        <label className="col-form-label fw-normal">Of every</label>
                                                    </div>
                                                    <div className="col-auto">
                                                        <input type="text" className="form-control mxw-50" />
                                                    </div>
                                                    <div className="col-auto">
                                                        <label className="col-form-label fw-normal">Month (s)</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </div>
                        </div>
                    </>
                }
            </Offcanvas.Body>
            <div className='offcanvas-footer'>
                <div className="btn-toolbar justify-content-end">
                    <button className="btn btn-lg btn-link px-0" onClick={(e) => { dispatch(handleCloseModal()) }}>Cancel</button>
                    <button className="btn btn-lg btn-secondary">Save</button>
                </div>
            </div>
        </Offcanvas>
    );
}



export default AddTaskModal;