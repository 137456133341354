import { sidebarExpanded } from "../../action";
import { setEmailProvider } from "../../api/helper.js";

const initialState =
{
    account_id: 0,
    emailProvider: null,
    emailBoxMenuLoaded: false,
    isShowAccountModal: false,
    isShowOffcanvasModal: false,
    isShowSendLaterModal: false,
    integrationStepKey: 'intStep1',
    searchData: false,
    gmailAuthUrl: '',
    inbox: { 'emails': [] },
    sent: { 'emails': [] },
    outbox: { 'emails': [] },
    scheduled: { 'emails': [] },
    draft: { 'emails': [] },
    spam: { 'emails': [] },
    trash: { 'emails': [] },
    search: { 'emails': [] },
    templates: { 'emails': [] },
    emailsList: { 'accounts': [] },
    emailAvailableMenus: {
        inbox: null,
        sent: null,
        outbox: null,
        scheduled: null,
        draft: null,
        spam: null,
        trash: null
    },
    menuItems: [],
    pageTokens: {
        inbox: {},
        sent: {},
        outbox: {},
        scheduled: {},
        draft: {},
        spam: {},
        trash: {},
        search: {},
    },
    emailMenuItems: ['INBOX', 'SENT', 'DRAFT', 'SPAM', 'TRASH'],
    filterDateItems: [
        { "key": "today", "text": "Today" },
        { "key": "yesterday", "text": "Yesterday" },
        { "key": "last_7_day", "text": "Last 7 Days" },
        { "key": "current_month", "text": "Current Month" },
        { "key": "current_year", "text": "Current Year" },
        { "key": "previous_month", "text": "Previouse Month" },
    ],
    pageSize: 40,
    //
    currentEmailBox: 'inbox',
    selectedMsgCount: 0,
    selectedUnreadMsgCount: 0,
    totalMsgCountData: {
        inbox: 0,
        sent: 0,
        draft: 0,
        spam: 0,
        trash: 0,
        search: 0,
    },
    unReadMsgCountData: {
        inbox: 0,
        sent: 0,
        draft: 0,
        spam: 0,
        trash: 0,
    },
    emailSettingData: {
        "fromEmail": "",
        "replyToEmail": "",
    },
    isDeleteConfirmModal: false,
    isSelectMenuItem: false,
}

const reducer = (state = initialState, action) => {
    const newState = { ...state };
    switch (action.type) {
        case "testclick":
            return {
                ...state,
                action
            }

        case "setCurrentEmailBox":
            state.currentEmailBox = action.payload;
            return {
                ...state,
                action
            }

        case "setEmailBoxMenuLoaded":
            state.emailBoxMenuLoaded = action.payload;
            return {
                ...state,
                action
            }

        case "handleCommonModal":
            if (action.key === 'accountModal') {
                state.isShowAccountModal = !state.isShowAccountModal;
            }
            if (action.key === 'taskModal') {
                state.isShowOffcanvasModal = !state.isShowOffcanvasModal;
            }
            if (action.key === 'sendLaterModal') {
                state.isShowSendLaterModal = !state.isShowSendLaterModal;
            }

            return {
                ...state,
                action
            }

        case "handleCloseModal":
            state.isShowAccountModal = false;
            state.isShowOffcanvasModal = false;
            state.isShowSendLaterModal = false;

            return {
                ...state,
                action
            }

        case "integrationStep":
            state.integrationStepKey = action.key;
            state.isShowAccountModal = false;

            return {
                ...state,
                action
            }

        case "sidebarExpanded":
            if (action.key === 'sidebar-collapse') {
                document.querySelector('body').classList.toggle("sidebar-expanded")
            }
            return {
                ...state,
                action
            }

        case "setAccountId":
            state.account_id = action.payload;
            return {
                ...state,
                action
            }

        case "setEmails":
            if (action.key === 'inbox') {
                state.inbox.emails = action.emails.emails;
                state.inbox.pageIndex = action.pageIndex;
            } else if (action.key === 'sent') {
                state.sent.emails = action.emails.emails;
                state.sent.pageIndex = action.pageIndex;
            } else if (action.key === 'outbox') {
                state.outbox.emails = action.emails.emails;
                state.outbox.pageIndex = action.pageIndex;
            } else if (action.key === 'scheduled') {
                state.scheduled.emails = action.emails.emails;
                state.scheduled.pageIndex = action.pageIndex;
            } else if (action.key === 'draft') {
                state.draft.emails = action.emails.emails;
                state.draft.pageIndex = action.pageIndex;
            } else if (action.key === 'spam') {
                state.spam.emails = action.emails.emails;
                state.spam.pageIndex = action.pageIndex;
            } else if (action.key === 'trash') {
                state.trash.emails = action.emails.emails;
                state.trash.pageIndex = action.pageIndex;
            } else if (action.key === 'search') {
                state.search.emails = action.emails.emails;
                state.search.pageIndex = action.pageIndex;
            }

            if (action.pageIndex) {
                state.pageTokens[action.key][action.pageIndex + 1] = action.emails.nextPageToken;
            }

            return {
                ...state,
                action
            }

        case "onSelectChanged":
            if (action.box in state) {
                let box = state[action.box];
                let tempSelectedMsgCount = 0;
                let tempSelectedUnreadMsgCount = 0;
                box.emails.map(email => {
                    if (email.thread_id === action.thread_id) {
                        email.selected = action.event.target.checked;
                        console.log(email);
                    }
                    if (email.selected) {
                        tempSelectedMsgCount += 1;
                        if (!email.is_read)
                            tempSelectedUnreadMsgCount += 1;
                    }
                });
                state.selectedMsgCount = tempSelectedMsgCount;
                state.selectedUnreadMsgCount = tempSelectedUnreadMsgCount;
            }

            return {
                ...state,
                action
            }

        case "setMenuItems":
            state.menuItems = action.menuItems;
            state.emailAvailableMenus.inbox = null;
            state.emailAvailableMenus.sent = null;
            state.emailAvailableMenus.outbox = null;
            state.emailAvailableMenus.scheduled = null;
            state.emailAvailableMenus.draft = null;
            state.emailAvailableMenus.spam = null;
            state.emailAvailableMenus.trash = null;
            // state.emailMenuItems = [];
            action.menuItems.map((menuItem) => {
                if (menuItem.id === 'INBOX' || menuItem.name === 'Inbox') {
                    state.emailAvailableMenus.inbox = menuItem;
                    state.unReadMsgCountData.inbox = menuItem.unread_count
                }
                else if (menuItem.id === 'SENT' || menuItem.name === 'Sent Items') {
                    state.emailAvailableMenus.sent = menuItem;
                    state.unReadMsgCountData.sent = menuItem.unread_count
                }
                else if (menuItem.id === 'DRAFT' || menuItem.name === 'Drafts') {
                    state.emailAvailableMenus.draft = menuItem;
                    state.unReadMsgCountData.draft = menuItem.unread_count
                }
                else if (menuItem.id === 'SPAM' || menuItem.name === 'Junk Email') {
                    state.emailAvailableMenus.spam = menuItem;
                    state.unReadMsgCountData.spam = menuItem.unread_count
                }
                else if (menuItem.id === 'TRASH' || menuItem.name === 'Deleted Items') {
                    state.emailAvailableMenus.trash = menuItem;
                    state.unReadMsgCountData.trash = menuItem.unread_count
                }
                else
                    return;

                // state.emailMenuItems.push(menuItem.id);
            });

            state.inbox = { 'emails': [] };
            state.sent = { 'emails': [] };
            state.outbox = { 'emails': [] };
            state.scheduled = { 'emails': [] };
            state.draft = { 'emails': [] };
            state.spam = { 'emails': [] };
            state.trash = { 'emails': [] };
            state.search = { 'emails': [] };
            state.pageTokens = {
                inbox: {},
                sent: {},
                outbox: {},
                scheduled: {},
                draft: {},
                spam: {},
                trash: {},
                search: {},
            };

            return {
                ...state,
                action
            }

        case "setEmailsList":
            state.emailsList = action.emailsList;
            if (state.emailsList.accounts.length > 0) {
                state.emailProvider = state.emailsList.accounts[0].provider;
                setEmailProvider(state.emailProvider);
            }

            return {
                ...state,
                action
            }

        case "setEmailProvider":
            state.emailProvider = action.provider;
            setEmailProvider(state.emailProvider);
            return {
                ...state,
                action
            }

        case "selectEmails":
            if (action.box in state) {
                let box = state[action.box];
                let opt = action.opt;
                let tempSelectedMsgCount = 0;
                let tempSelectedUnreadMsgCount = 0;
                box.emails.map(email => {
                    if (opt === 'All') {
                        email.selected = true;
                    } else if (opt === 'None') {
                        email.selected = false;
                    } else if (opt === 'Read') {
                        email.selected = email.is_read ? true : false;
                    } else if (opt === 'Unread') {
                        email.selected = email.is_read ? false : true;
                    }

                    if (email.selected) {
                        tempSelectedMsgCount += 1;
                        if (!email.is_read)
                            tempSelectedUnreadMsgCount += 1;
                    }
                });
                state.selectedMsgCount = tempSelectedMsgCount;
                state.selectedUnreadMsgCount = tempSelectedUnreadMsgCount;
            }
            return {
                ...state,
                action
            }

        case "setEmailSettingData":
            state.emailSettingData = action.payload;
            return {
                ...state,
                action
            }

        case "setSelectedMsgCount":
            state.selectedMsgCount = action.payload;
            return {
                ...state,
                action
            }

        case "setSelectedUnreadMsgCount":
            state.selectedUnreadMsgCount = action.payload;
            return {
                ...state,
                action
            }

        case "setUnreadMsgCoundData":
            state.unReadMsgCountData = action.payload;

            return {
                ...state,
                action
            }

        case "setTotalMsgCountData":
            state.totalMsgCountData = action.payload;

            return {
                ...state,
                action
            }

        case "setIsDeleteConfirmModal":
            state.isDeleteConfirmModal = action.payload;
            return {
                ...state,
                action
            }

        case "setIsSelectMenuItem":
            state.isSelectMenuItem = action.payload;
            return {
                ...state,
                action
            }

        default:
            return newState;
    }
}

export default reducer;   