import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import EmailTopbar from '../EmailTopbar/EmailTopbar';
import LoadingOverlay from 'react-loading-overlay-ts';

import { formatDate } from "../../../api/helper.js";
import { getSearchResultMessages, batchTrashEmail } from "../../../api/index.js";
import { setEmails, onSelectChanged, setTotalMsgCountData, setCurrentEmailBox, setIsSelectMenuItem } from "../../../action/index.js";

import './style.scss';
import { BeatLoader } from 'react-spinners';

const Search = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [pageIndex, setPageIndex] = useState(1);
  const [loading, setLoading] = useState(false);

  const isSelectMenuItem = useSelector((state) => state.reducer.isSelectMenuItem);
  const props = useSelector((state) => state.reducer);
  const pageTokens = useSelector((state) => state.reducer.pageTokens);
  const accountId = useSelector((state) => state.reducer.account_id);
  const totalMsgCountData = useSelector((state) => state.reducer.totalMsgCountData);

  const fetchData = async (updatedParams) => {
    try {
      setLoading(true)
      const params = (updatedParams === undefined) ? state.params : updatedParams;
      params['pageToken'] = pageTokens.search[pageIndex];
      const googleThreadsResponse = await getSearchResultMessages(accountId, params);

      dispatch(setTotalMsgCountData({
        ...totalMsgCountData,
        'search': googleThreadsResponse.countOfResult
      }))
      dispatch(setEmails('search', googleThreadsResponse, pageIndex));
    } catch (err) {
      // console.error(err)
    }
    finally {
      setLoading(false);
    }
  };
  const onRefresh = () => {
    fetchData();
  }
  const onDelete = async () => {
    let emails = props.search.emails;
    let threadIDs = [];
    emails.map(async (email) => {
      if (email.selected) {
        threadIDs.push(email.thread_id);
      }
    });
    if (threadIDs.length > 0) {
      try {
        dispatch(setEmails('search', { emails: emails.filter((email) => !threadIDs.includes(email.thread_id)) }))
        await batchTrashEmail(threadIDs, accountId);
      } catch (err) {
        console.error(err);
      }
    }
  }
  const onUpdateParams = async (updatedParams) => {
    fetchData(updatedParams);
  };
  const onPrevPage = () => {
    if (pageIndex > 1) {
      setPageIndex(pageIndex - 1);
    }
  }
  const onNextPage = () => {
    if (pageTokens.search[pageIndex + 1]) {
      setPageIndex(pageIndex + 1);
    }
  }

  useEffect(() => {
    dispatch(setCurrentEmailBox('search'))
  }, [])

  useEffect(() => {
    if (isSelectMenuItem) {
      setPageIndex(1);
    }
  }, [isSelectMenuItem])

  useEffect(() => {
    setLoading(true)
    if (accountId !== 0) {
      fetchData();
      dispatch(setIsSelectMenuItem(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId, pageIndex]);

  return (
    <LoadingOverlay active={loading} spinner text='Loading ...' >
      <div className="section-content">
        <div className="media-list media-list-divided media-list-hover media-email">
          <EmailTopbar onRefresh={() => onRefresh()} onDelete={() => onDelete()} box="search" pageIndex={pageIndex} onNextPage={() => onNextPage()} onPrevPage={() => onPrevPage()} onUpdateParams={(params) => onUpdateParams(params)} keyword={state.keyword} />
          <div className="media-list-body content-body scrollbar">
            <div className='media-title-header'><strong className='title'>Search</strong></div>
            {loading ?
              <div className="media media-search">
                <BeatLoader color="#36d7b7" />
              </div>
              :
              ((props.search.emails?.length === 0 && !loading) ?
                <div className="media media-search">
                  No matching data.
                </div>
                :
                props.search.emails?.map((thread, index) => (
                  <div className="media media-search" key={thread.thread_id}>
                    <div className="form-check custom-checkbox mb-0 title-part">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={thread.selected ? true : false}
                        id={thread.thread_id}
                        onChange={(event) => dispatch(onSelectChanged(event, 'search', thread.thread_id))}
                      />
                      <div style={{ display: "flex" }}>
                        <div className="form-check-label text-truncate" htmlFor={thread.thread_id}>
                          {(location.state.params.labelId === "DRAFT" || location.state.params.labelId === "SENT") ? thread.to[0].name : thread.from.name}
                        </div>
                        {location.state.params.labelId === "DRAFT" && thread.to[0].name && <p>,&nbsp;</p>}
                        {location.state.params.labelId === "DRAFT" && <div className="form-check-label title-text-truncate" htmlFor={thread.thread_id} style={{ color: "#F07D12" }}>Draft</div>}
                      </div>
                    </div>
                    <div className="content-data media-body" onClick={() => { navigate(`/email/thread/+${thread.thread_id}`); }} >
                      <div className='media-subject content-title d-block'>{thread.subject}</div>
                      <p className='text-truncate m-auto content-text ps-10'>{thread.snippet}</p>
                    </div>
                    <div className='media-info'>
                      {thread.has_attachments && <span className="media-file"><div className="icon-file" style={{ marginTop: "5px" }} /></span>}
                      <small className="media-dayTime">{formatDate(thread.date_time)}</small>
                    </div>
                  </div>
                )
                ))
            }
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
}

export default Search;