import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import LoadingOverlay from 'react-loading-overlay-ts';
import EmailTopbar from '../EmailTopbar/EmailTopbar'

import { setEmails, onSelectChanged, setCurrentEmailBox, setIsSelectMenuItem } from "../../../action/index.js";
import { getGoogleThreads, batchTrashEmail, batchMarkAsSpam } from "../../../api/index.js";
import { formatDate } from "../../../api/helper.js";

import { SvgAttachment } from '../../../utils/IconInfo';

const Sent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageIndex, setPageIndex] = useState(1);
  const [loading, setLoading] = useState(true);

  const isSelectMenuItem = useSelector((state) => state.reducer.isSelectMenuItem);
  const props = useSelector((state) => state.reducer);
  const accountId = useSelector((state) => state.reducer.account_id);
  const emailAvailableMenus = useSelector((state) => state.reducer.emailAvailableMenus);
  const emailBoxMenuLoaded = useSelector((state) => state.reducer.emailBoxMenuLoaded);

  const fetchData = async () => {
    try {
      setLoading(true);
      const googleThreadsResponse = await getGoogleThreads(props.emailAvailableMenus.sent.id, props.pageTokens.sent[pageIndex], accountId);
      dispatch(setEmails('sent', googleThreadsResponse, pageIndex));
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  const onRefresh = () => {
    fetchData();
  }
  const onDelete = async () => {
    let emails = props.sent.emails;
    let threadIDs = [];
    emails.map(async (email) => {
      if (email.selected) {
        threadIDs.push(email.thread_id);
      }
    });
    if (threadIDs.length > 0) {
      try {
        dispatch(setEmails('sent', { emails: emails.filter((email) => !threadIDs.includes(email.thread_id)) }))
        await batchTrashEmail(threadIDs, accountId);
      } catch (err) {
        // error handling
      }
    }
  }
  const onSpam = async () => {
    let emails = props.sent.emails;
    let threadIDs = [];
    emails.map(async (email) => {
      if (email.selected) {
        threadIDs.push(email.thread_id);
      }
    });
    if (threadIDs.length > 0) {
      try {
        dispatch(setEmails('sent', { emails: emails.filter((email) => !threadIDs.includes(email.thread_id)) }))
        await batchMarkAsSpam(threadIDs, accountId);
      } catch (err) {
        // console.error(err);
      }
    }
  }
  const onPrevPage = () => {
    if (pageIndex > 1) {
      setPageIndex(pageIndex - 1);
    }
  }
  const onNextPage = () => {
    if (props.pageTokens.sent[pageIndex + 1]) {
      setPageIndex(pageIndex + 1);
    }
  }

  useEffect(() => {
    dispatch(setCurrentEmailBox('sent'))
  }, [])

  useEffect(() => {
    if (isSelectMenuItem) {
      setPageIndex(1);
    }
  }, [isSelectMenuItem])

  useEffect(() => {
    setLoading(true)
    if (accountId !== 0 && props.emailAvailableMenus.sent !== null) {
      fetchData()
      dispatch(setIsSelectMenuItem(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailAvailableMenus.sent, pageIndex]);

  return (
    <LoadingOverlay active={loading || !emailBoxMenuLoaded} spinner text='Loading ...' >
      <div className="section-content">
        <div className="media-list media-list-divided media-list-hover media-email">
          <EmailTopbar onRefresh={() => onRefresh()} onDelete={() => onDelete()} onSpam={() => onSpam()} box="sent" pageIndex={pageIndex} onNextPage={() => onNextPage()} onPrevPage={() => onPrevPage()} />
          <div className="media-list-body scrollbar">
            <div className='media-title-header'><strong className='title'>Sent </strong></div>
            {loading || !emailBoxMenuLoaded ?
              <div className="media media-schedule">
                <BeatLoader color="#36d7b7" />
              </div>
              :
              props.sent.emails.length === 0 && !loading ?
                <div className="media media-inbox">
                  There is no email in this box.
                </div>
                :
                props.sent.emails.map((thread) => {
                  return (
                    <div className="media media-schedule" key={thread.thread_id}>
                      <div className="form-check custom-checkbox title-part">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={thread.selected ? true : false}
                          id={thread.thread_id}
                          onChange={(event) => dispatch(onSelectChanged(event, 'sent', thread.thread_id))}
                        />
                        <div className="form-check-label title-text-truncate" htmlFor={thread.thread_id}>To: {thread.to[0].name}</div>
                        <div className='media-mail text-truncate'>&lt; {thread.to[0].email} &gt;</div>
                      </div>
                      <div className="content-data media-body d-block" onClick={() => { navigate(`/email/thread/+${thread.thread_id}`); }} >
                        <div className='d-flex'>
                          <div className='media-subject content-title d-block'>{thread.subject}</div>
                          <span className='sent-email-state-section ps-10'>
                            {thread.is_opened && <div >Opened</div>}
                            {thread.is_opened && thread.is_clicked && <span className="divider-line"></span>}
                            {thread.is_clicked && <div>Clicked</div>}
                          </span>
                        </div>
                        <p className='text-truncate m-auto content-text'>{thread.snippet}</p>
                      </div>
                      <div className='media-info'>
                        {thread.has_attachments && <span className="media-file"><SvgAttachment className='icon-download' width="12px" height='12px' color='#5F6267' /></span>}
                        <small className="media-dayTime">{formatDate(thread.date_time)}</small>
                      </div>
                    </div>
                  )
                }
                )
            }

          </div>
        </div>
      </div>
    </LoadingOverlay >
  );
}


export default Sent;