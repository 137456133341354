import { getUserId, getEmailProvider } from "./helper.js";

const sensei_email_base = "https://apiemail.senseicrm.com/api/v1";
const gmail_uri = "gmail";
const office_uri = "outlook";

export const getGoogleAuthUrl = async () => {
  const response = await fetch(`${sensei_email_base}/${gmail_uri}/oauth`, {
    method: 'GET',
    headers: {
      'user-id': `${getUserId()}`,
      'Content-Type': 'application/json'
    },
  });
  return response.json();
};

export const checkGoogleAuth = async (accountId) => {
  const response = await fetch(`${sensei_email_base}/check`, {
    method: 'GET',
    headers: {
      'user-id': `${getUserId()}`,
      'account-id': accountId,
      'Content-Type': 'application/json'
    },
  });
  return response.json();
};

export const getGoogleThreads = async (labelId, pageToken, accountId) => {
  const data = {
    'labelId': labelId
  };
  let pageTokenUrl = "";
  if (pageToken) {
    pageTokenUrl = '&pageToken=' + pageToken;
  }
  const response = await fetch(`${sensei_email_base}/${getEmailProvider()}/threads?labelId=${labelId}${pageTokenUrl}`, {
    method: 'GET',
    headers: {
      'user-id': `${getUserId()}`,
      'account-id': accountId,
      'Content-Type': 'application/json'
    },
    data: data
  });
  return response.json();
};

export const getGoogleThread = async (threadId, accountId) => {
  const response = await fetch(`${sensei_email_base}/${getEmailProvider()}/threads/${threadId}`, {
    method: 'GET',
    headers: {
      'user-id': `${getUserId()}`,
      'account-id': accountId,
      'Content-Type': 'application/json'
    }
  });
  return response.json();
};

export const getGoogleMenuItems = async (accountId) => {
  const response = await fetch(`${sensei_email_base}/${getEmailProvider()}/menu/`, {
    method: 'GET',
    headers: {
      'user-id': `${getUserId()}`,
      'account-id': accountId,
      'Content-Type': 'application/json'
    },
  });
  return response.json();
};

export const getGoogleEmailsList = async () => {
  const response = await fetch(`${sensei_email_base}/accounts`, {
    method: 'GET',
    headers: {
      'user-id': `${getUserId()}`,
      'Content-Type': 'application/json'
    },
  });
  return response.json();
};

export const sendEmail = async (messageId, accountId, emailContent) => {
  await fetch(`${sensei_email_base}/${getEmailProvider()}/drafts/${messageId}`, {
    method: 'POST',
    headers: {
      'user-id': `${getUserId()}`,
      'account-id': accountId,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(emailContent),
  });
}

export const draftSave = async (messageId, accountId, emailContent) => {
  let res;
  if (messageId === null) {
    const messageId = await createDraftMessage(accountId);
    res = await updateDraftMessage(messageId.id, accountId, emailContent);
  } else {
    res = await updateDraftMessage(messageId, accountId, emailContent);
  }
  return res;
}

export const draftReplySave = async (composeType, sourceMessageId, accountId, emailContent) => {
  let messageId;
  if (composeType === "reply" || composeType === "replyToAll") {
    messageId = await createDraftReply(sourceMessageId, accountId);
    console.log('messageId: ', messageId);
  } else if (composeType === "forward") {
    messageId = await createForwardReply(sourceMessageId, accountId);
  }
  const res = await updateDraftMessage(messageId.id, accountId, emailContent);
  console.log('res: ', res);
  return messageId.id;
}

export const createDraftMessage = async (accountId) => {
  const response = await fetch(`${sensei_email_base}/${gmail_uri}/drafts`, {
    method: 'POST',
    headers: {
      'user-id': `${getUserId()}`,
      'account-id': accountId,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
  });
  return response.json();
}

export const createDraftReply = async (sourceMessageId, accountId) => {
  console.log('sourceMessageId: ', sourceMessageId);
  const response = await fetch(`${sensei_email_base}/${gmail_uri}/messages/reply/${sourceMessageId}`, {
    method: 'POST',
    headers: {
      'user-id': `${getUserId()}`,
      'account-id': accountId,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  });
  return response.json();
}

export const createForwardReply = async (sourceMessageId, accountId) => {
  const response = await fetch(`${sensei_email_base}/${gmail_uri}/messages/forward/${sourceMessageId}`, {
    method: 'POST',
    headers: {
      'user-id': `${getUserId()}`,
      'account-id': accountId,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  });
  return response.json();
}

export const updateDraftMessage = async (messageId, accountId, emailContent) => {
  const response = await fetch(`${sensei_email_base}/${gmail_uri}/drafts/${messageId}`, {
    method: 'PUT',
    headers: {
      'user-id': `${getUserId()}`,
      'account-id': accountId,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    body: JSON.stringify(emailContent)
  });
  return response.json();
}

export const uploadAttachmentFile = async (messageId, accountId, attachedFiles) => {
  const formData = new FormData();
  attachedFiles.map((item) => {
    formData.append('file[]', item);
  })

  const response = await fetch(`${sensei_email_base}/${gmail_uri}/attachments/upload/${messageId}`, {
    method: 'POST',
    headers: {
      'user-id': `${getUserId()}`,
      'account-id': accountId,
      'Accept': 'application/json'
    },
    body: formData
  }).then(res => res.json()).catch(err => {
    console.error(err);
  });
  return response;
}

export const deleteAttachmentFile = async (messageId, accountId, fileId) => {
  await fetch(`${sensei_email_base}/${gmail_uri}/attachments/delete/${messageId}/${fileId}`, {
    method: 'DELETE',
    headers: {
      'user-id': `${getUserId()}`,
      'account-id': accountId,
      'Accept': 'application/json'
    }
  })
}

export const getBlobFromUrl = async (accountId, url) => {
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'user-id': `${getUserId()}`,
      'account-id': accountId,
      'Accept': 'application/json'
    }
  });
  return response.blob();
};

export const batchTrashEmail = (threadIDs, accountId) =>
  fetch(`${sensei_email_base}/${getEmailProvider()}/threads/batchTrash`, {
    method: 'POST',
    headers: {
      'user-id': `${getUserId()}`,
      'account-id': accountId,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    body: JSON.stringify({
      "ids": threadIDs
    })
  });

export const trashEmail = async (threadID, accountId) => {
  const response = await fetch(`${sensei_email_base}/${getEmailProvider()}/threads/trash/${threadID}`, {
    method: 'POST',
    headers: {
      'user-id': `${getUserId()}`,
      'account-id': accountId,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  });
  return response.json();
}

export const batchDeleteEmail = async (threadIDs, accountId) =>
  fetch(`${sensei_email_base}/${getEmailProvider()}/threads/batchDelete`, {
    method: 'DELETE',
    headers: {
      'user-id': `${getUserId()}`,
      'account-id': accountId,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      "ids": threadIDs
    })
  });

export const deleteEmail = async (threadID) => {
  const response = await fetch(`${sensei_email_base}/${getEmailProvider()}/threads/${threadID}`, {
    method: 'DELETE',
    headers: {
      'user-id': `${getUserId()}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  });
  return response.json();
}

export const getSearchResultMessages = async (accountId, params) => {
  let paramUrl = 't';
  if (params['labelId']) {
    paramUrl += '&labelId=' + params['labelId'];
  }
  if (params['attachments']) {
    paramUrl += '&attachments=' + params['attachments'];
  }
  if (params['q']) {
    paramUrl += '&q=' + params['q'];
  }
  if (params['date']) {
    paramUrl += '&date=' + params['date'];
  }
  if (params['from_me']) {
    paramUrl += '&from_me=true';
  }
  if (params['customRangeStarted']) {
    paramUrl += '&customRangeStarted=' + params['customRangeStarted'];
  }
  if (params['customRangeTo']) {
    paramUrl += '&customRangeTo=' + params['customRangeTo'];
  }
  if (params['pageToken']) {
    paramUrl += '&pageToken=' + params['pageToken'];
  }
  console.log('paramUrl: ', paramUrl);
  const response = await fetch(`${sensei_email_base}/${getEmailProvider()}/messages/search?${paramUrl}`, {
    method: 'GET',
    headers: {
      'user-id': `${getUserId()}`,
      'account-id': accountId,
      'Content-Type': 'application/json'
    }
  });
  return response.json();
};

export const batchMarkAsSpam = async (threadIDs, accountId) => {
  const response = await fetch(`${sensei_email_base}/${getEmailProvider()}/threads/batchMarkAsSpam`, {
    method: 'POST',
    headers: {
      'user-id': `${getUserId()}`,
      'account-id': accountId,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    body: JSON.stringify({
      'ids': threadIDs
    })
  });
  return response.json();
}

export const batchMarkAsNotSpam = async (threadIDs, accountId) => {
  const response = await fetch(`${sensei_email_base}/${getEmailProvider()}/threads/batchMarkAsNotSpam`, {
    method: 'POST',
    headers: {
      'user-id': `${getUserId()}`,
      'account-id': accountId,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    body: JSON.stringify({
      'ids': threadIDs
    })
  });
  return response.json();
}

export const getOfficeAuthUrl = async () => {
  const response = await fetch(`${sensei_email_base}/${office_uri}/oauth`, {
    method: 'GET',
    headers: {
      'user-id': `${getUserId()}`,
      'Content-Type': 'application/json'
    },
  });
  return response.json();
};

export const checkOfficeAuth = async () => {
  const response = await fetch(`${sensei_email_base}/${office_uri}/check`, {
    method: 'GET',
    headers: {
      'user-id': `${getUserId()}`,
      'Content-Type': 'application/json'
    },
  });
  return response.json();
};

export const emailSettingSignature = async (signatureData, accountId) => {
  fetch(`${sensei_email_base}/signature`, {
    method: 'POST',
    headers: {
      'user-id': `${getUserId()}`,
      'account-id': accountId,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      "signature": signatureData
    })
  })
}

export const accountLogOut = async (accountId) => {
  await fetch(`${sensei_email_base}/logout`, {
    method: 'GET',
    headers: {
      'user-id': `${getUserId()}`,
      'account-id': accountId,
    }
  });
}

export const deleteMessage = async (accountId, messageId) => {
  console.log('messageId: ', messageId);
  await fetch(`${sensei_email_base}/${gmail_uri}/messages/trash/${messageId}`, {
    method: 'POST',
    headers: {
      'user-id': `${getUserId()}`,
      'account-id': accountId,
      'Content-Type': 'application/json'
    }
  })
}