import React from 'react';
import { Routes, Route, useLocation } from "react-router-dom";

import EmailHeader from './EmailHeader/EmailHeader'
import EmailSidebar from './EmailSidebar/EmailSidebar'
import Integration from './Integration/Integration'
import Compose from './Compose/Compose.js'
import Inbox from './Inbox/Inbox'
import Thread from './Thread/Thread'
import Sent from './Sent/Sent'
import Outbox from './Outbox/Outbox'
import Scheduled from './Scheduled/Scheduled'
import Drafts from './Drafts/Drafts'
import Spam from './Spam/Spam'
import Trash from './Trash/Trash'
import Search from './Search/Search'
import Templates from './Templates/Templates'
import EmailSettings from './EmailSettings/EmailSettings'

const Email = () => {

  const location = useLocation();

  return (
    <div className='w-100'>
      <EmailHeader />
      <main className="main-container">
        {location.pathname !== "/" && location.pathname !== "/email/settings" ? <EmailSidebar /> : ''}
        <section className="main-content">
          <Routes>
            <Route path="/" element={<Integration />} />
            <Route path="/email/compose" element={<Compose composeType='new' />} />
            <Route path="/email/inbox" element={<Inbox />} />
            <Route path="/email/thread/:threadId" element={<Thread />} />
            <Route path="/email/sent" element={<Sent />} />
            <Route path="/email/outbox" element={<Outbox />} />
            <Route path="/email/scheduled" element={<Scheduled />} />
            <Route path="/email/draft" element={<Drafts />} />
            <Route path="/email/spam" element={<Spam />} />
            <Route path="/email/trash" element={<Trash />} />
            <Route path="/email/search" element={<Search />} />
            <Route path="/email/templates" element={<Templates />} />
            <Route path="/email/settings" element={<EmailSettings />} />
          </Routes>
        </section>
      </main>
    </div>
  );
}

export default Email;