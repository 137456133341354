import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from "react-redux";

import { handleCloseModal, integrationStep } from "../../action/index.js";
import GoogleAccount from '../../assets/images/google-account.jpg';

const AccountModal = () => {
  const dispatch = useDispatch();
  const props = useSelector((state) => state.reducer);

  return (
    <Modal
      show={props.isShowAccountModal}
      onHide={(e) => { dispatch(handleCloseModal()) }}
      backdrop="static"
      keyboard={false}
      size="md"
      centered
    >
      <Modal.Body className='text-center'>
        <div className='accounts'>
          <figure><img src={GoogleAccount} alt="GoogleAccount" /></figure>
          <div className='btn-toolbar justify-'>
            <button className="btn btn-outline-dark btn-lg" onClick={(e) => { dispatch(handleCloseModal()) }}>Cancel</button>
            <button className="btn btn-outline-dark btn-lg" onClick={(e) => { dispatch(integrationStep('intStep2')) }}>Allow</button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default AccountModal;