import React from 'react';
import { Link, useNavigate } from "react-router-dom";
import { FloatingPortal, useFloating } from '@floating-ui/react';

import Dropdown from 'react-bootstrap/Dropdown';
import Badge from 'react-bootstrap/Badge';
import { setUserId } from '../../../api/helper';

import { SvgCompany, SvgEdit, SvgLogout, SvgPassword, SvgUser, SvgUserProfile } from '../../../utils/IconInfo'

const EmailHeader = () => {

    const navigate = useNavigate();
    const { refs, floatingStyles } = useFloating();

    const signOut = () => {
        setUserId(null);
        navigate("/");
    }

    return (
        <header className="topbar">
            <div className="topbar-right">
                <div className="topbar-btns">
                    {/* <Dropdown align="end" className='dropdown-add d-none'>
                        <Dropdown.Toggle variant="transparent" className="topbar-btn"><i className="icon-add-circle"></i></Dropdown.Toggle>
                        <Dropdown.Menu>
                            {['Create Lead', 'Create Customer', 'Create Quote', 'Create Order', 'Create Inventory', 'Create Report', 'Connect Email' ].map((i) =>
                                <Dropdown.Item href="/" key={i}>{i}</Dropdown.Item>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown align="end" className='dropdown-notify d-none'>
                        <Dropdown.Toggle variant="transparent" className="topbar-btn has-new"><Badge bg="primary">33</Badge><i className="icon-notification"></i></Dropdown.Toggle>
                        <Dropdown.Menu className='media-list media-list-divided'>
                            <Dropdown.Header>Notification <span className="badge">(33)</span></Dropdown.Header>
                            <div className='dropdown-body scrollbar'>
                                <Dropdown.Item className="media" href="/">
                                    <div className="media-left"><span className='avatar avatar-md'><img src={PngAvatar} alt="Avatar" /></span></div>
                                    <div className="media-body">
                                        <div className='flexbox align-items-center'>
                                            <p>A new email received from</p>
                                            <span>
                                                <small>12:45 PM</small>
                                                <i className='close icon-close'></i>
                                            </span>
                                        </div>
                                        <b>Jane Smith</b>
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Item className="media" href="/">
                                    <div className="media-left"><span className='avatar avatar-md avatar-alphabet'>A</span></div>
                                    <div className="media-body">
                                        <div className='flexbox align-items-center'>
                                            <p>A new email received from</p>
                                            <span>
                                                <small>24 Aug</small>
                                                <i className='close icon-close'></i>
                                            </span>
                                        </div>
                                        <b>Animoto</b>
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Item className="media" href="/">
                                    <div className="media-left"><span className='avatar avatar-md'><img src={PngTaskReminder} alt="TaskReminder" /></span></div>
                                    <div className="media-body">
                                        <div className='flexbox align-items-center'>
                                            <p>Task Reminder</p>
                                            <span>
                                                <small>23 Aug</small>
                                                <i className='close icon-close'></i>
                                            </span>
                                        </div>
                                        <b>Angelica Baker</b>
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Item className="media" href="/">
                                    <div className="media-left"><span className='avatar avatar-md'><img src={SvgEmailOpened} alt="EmailOpened" /></span></div>
                                    <div className="media-body">
                                        <div className='flexbox align-items-center'>
                                            <p>Email Openned</p>
                                            <span>
                                                <small>Jesse B. Martin</small>
                                                <i className='close icon-close'></i>
                                            </span>
                                        </div>
                                        <b>Angelica Baker</b>
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Item className="media" href="/">
                                    <div className="media-left"><span className='avatar avatar-md'><img src={PngEmailClicked} alt="EmailClicked" /></span></div>
                                    <div className="media-body">
                                        <div className='flexbox align-items-center'>
                                            <p>Email Clicked</p>
                                            <span>
                                                <small>21 Aug</small>
                                                <i className='close icon-close'></i>
                                            </span>
                                        </div>
                                        <b>Mary  Ferrell</b>
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Item className="media" href="/">
                                    <div className="media-left"><span className='avatar avatar-md'><img src={PngAvatar} alt="..." /></span></div>
                                    <div className="media-body">
                                        <div className='flexbox align-items-center'>
                                            <p>A new email received from</p>
                                            <span>
                                                <small>12:45 PM</small>
                                                <i className='close icon-close'></i>
                                            </span>
                                        </div>
                                        <b>Jane Smith</b>
                                    </div>
                                </Dropdown.Item>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown> */}

                    <Dropdown align="end" className='dropdown-profile'>
                        <Dropdown.Toggle variant="transparent" className="topbar-btn" ref={refs.setReference}>
                            <SvgUser className='icon avatar' color="black" />
                        </Dropdown.Toggle>
                        <FloatingPortal>
                            <div ref={refs.setFloating} style={{ ...floatingStyles, zIndex: 11 }}>
                                <Dropdown.Menu className='media-list' style={{ backgroundColor: "white", width: "500px" }} >
                                    <Dropdown.Header style={{ background: "#F5F7F9" }}>
                                        <div className='profile-avatar'>
                                            <figure className='avatar avatar-lg'>
                                                <SvgUserProfile color="pink" />
                                            </figure>
                                            <div className='profile-content'>
                                                <div className='profile-name'>
                                                    <span className='text'>Anahit Pearce</span>
                                                    <span className='divider-line'></span>
                                                    <Link to='/' className='icon link-gray'>
                                                        <SvgEdit className='icon icon-pencil' color="#5F6267" />
                                                    </Link>
                                                </div>
                                                <div className='profile-position'>
                                                    <span className='text'>Admin</span>
                                                    <span className='divider-line'></span>
                                                    <span className='text'>anahitpearcegmail.com</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Dropdown.Header>
                                    <div className='dropdown-body scrollbar'>
                                        <Dropdown.ItemText className="media">
                                            <div className="media-left">
                                                <span className='avatar avatar-md'>
                                                    <SvgCompany color="#5F6267" />
                                                </span>
                                            </div>
                                            <div className="media-body">
                                                <b>My Company</b>
                                                <p></p>
                                                <p>Add multiple comanies under your admin license</p>
                                                <Link to='/' className='btn-link'>Manage</Link>
                                            </div>
                                        </Dropdown.ItemText>
                                        <Dropdown.Divider />
                                        <Dropdown.ItemText className="media">
                                            <div className="media-left">
                                                <span className='avatar avatar-md'>
                                                    <SvgPassword color="#5F6267" />
                                                </span>
                                            </div>
                                            <div className="media-body">
                                                <b>Change Password</b>
                                                <p></p>
                                                <p>Change your password here.</p>
                                                <Link to='/' className='btn-link'>Know More</Link>
                                            </div>
                                        </Dropdown.ItemText>
                                        <Dropdown.Divider />
                                        <Dropdown.ItemText className='text-end'>
                                            <div onClick={() => { signOut() }} className="btn btn-link p-0">
                                                <SvgLogout className='icon icon-logout' color='#009BB0' />
                                                <span className='text'>Logout</span>
                                            </div>
                                        </Dropdown.ItemText>
                                    </div>
                                </Dropdown.Menu>
                            </div>
                        </FloatingPortal>
                    </Dropdown>
                </div>
            </div>
        </header>
    );
}


export default EmailHeader;