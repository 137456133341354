import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch, useSelector } from "react-redux";

import { handleCommonModal, setAccountId, setEmailsList } from "../../../action/index.js";
import { getUserId, setUserId } from "../../../api/helper.js";
import { getGoogleAuthUrl, checkGoogleAuth, getOfficeAuthUrl, checkOfficeAuth, getGoogleEmailsList } from "../../../api/index.js";

import Gmail from '../../../assets/images/gmail.png';
import Office from '../../../assets/images/office.png';
import MicrosoftOffice from '../../../assets/images/microsoft-office.png';
import Confetti from '../../../assets/images/confetti.png';
import { SvgArrowRight } from '../../../utils/IconInfo.js';

const Integration = () => {
    const dispatch = useDispatch();

    const [needCheckAuth, setNeedCheckAuth] = useState(false);
    const [googleAuthed, setGoogleAuthed] = useState(false);
    const [officeAuthed, setOfficeAuthed] = useState(false);
    const [integrationStep, setIntegrationStep] = useState('intStep1');
    const [accountLogged, setAccountLogged] = useState(false);

    const accountsList = useSelector((state) => state.reducer.emailsList)

    const fetchEmailsList = async () => {
        try {
            setAccountLogged(false);
            const emailsList = await getGoogleEmailsList();
            if (emailsList.accounts.length > 0) {
                dispatch(setEmailsList(emailsList));
                dispatch(setAccountId(emailsList.accounts[emailsList.accounts.length - 1].account_id));
                setAccountLogged(true);
            }
        } catch (err) {
            console.error(err)
        }
    };

    const googleAuthEvent = async () => {
        let win;
            try {
                const googleAuthUrlResponse = await getGoogleAuthUrl();
                if (googleAuthUrlResponse.url) {
                    win = window.open(googleAuthUrlResponse.url, 'GoogleAuthentication', 'width=972, height=660, modal=yes, alwaysRaised=yes');
                    const checkConnect = setInterval(function() {
                        if (!win || !win.closed) return;
                        clearInterval(checkConnect);
                        setNeedCheckAuth(true);
                    }, 100);
                }
            } catch (err) {
                console.error(err)
            } finally {
                const intervalHandle = setInterval(function() {
                    if (win && win.closed) {
                        fetchEmailsList();
                        window.clearInterval(intervalHandle);
                    }
                }, 100);
            }
    };

    const officeAuthEvent = async () => {
        try {
            const officeAuthUrlResponse = await getOfficeAuthUrl();
            if (officeAuthUrlResponse.url) {
                const win = window.open(officeAuthUrlResponse.url, 'OfficeAuthentication', 'width=972, height=660, modal=yes, alwaysRaised=yes');
                const checkConnect = setInterval(function() {
                    if (!win || !win.closed) return;
                    clearInterval(checkConnect);
                    setNeedCheckAuth(true);
                }, 100);
            }
        } catch (err) {
            console.error('office auth error', err);
        }
    };

    useEffect(() => {
        if (!getUserId()) setUserId(Date.now() + Math.floor(Math.random() * Math.pow(10, 13)));
        setIntegrationStep('intStep1');
    }, []);

    useEffect(() => {
        if (needCheckAuth && accountLogged) {
            const checkAuthRes = async () => {
                const googleCheckAuthResponse = await checkGoogleAuth(accountsList.accounts[accountsList.accounts.length - 1].account_id);
                if (googleCheckAuthResponse.authed) setGoogleAuthed(true);
                // const officeCheckAuthResponse = await checkOfficeAuth();
                // if (officeCheckAuthResponse.authed) setOfficeAuthed(true);
            };
            checkAuthRes().catch(console.error);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[needCheckAuth, accountLogged]);

    useEffect(() => {
        if (googleAuthed) {
            setIntegrationStep('intStep2');
        }
    },[googleAuthed]);

    // useEffect(() => {
    //     if (officeAuthed) {
    //         setIntegrationStep('intStep2');
    //     }
    // },[officeAuthed]);

    return (
        <>
            { integrationStep === 'intStep1' &&
                <div className="section-content section-integration">
                    <div className="integration-content scrollbar">
                        <div className="container">
                            <div className="integration-body">
                                <h1 className='title'>All your email in one place</h1>
                                <p className="lead">Send, schedule & read email all within Sensei CRM</p>
                                <h2 className='subtitle mb-40'>Connect with</h2>
                                <div className="connectbox">
                                    <div className="connectbox-item"  onClick={(e)=>{googleAuthEvent()}}>
                                        <img alt="Gmail" src={Gmail} width={150} />
                                    </div>
                                    <div className="connectbox-item overlay" onClick={(e)=>{officeAuthEvent()}}>
                                        <img src={Office} alt="Office" />
                                        <div className='overlay-text'>Coming Soon</div>
                                    </div>
                                    <div className="connectbox-item overlay" onClick={(e)=>{dispatch(handleCommonModal('accountModal'))}}>
                                        <img alt="Microsoft Office" src={MicrosoftOffice} />
                                        <div className='overlay-text'>Coming Soon</div>
                                    </div>
                                </div>
                            </div>
                            <div className="btn-toolbar">
                                <div className="btn btn-toolbar btn-lg btn-link"><b>I use a different provider</b></div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            { integrationStep === 'intStep2' &&
                <div className="section-content section-integration">
                    <div className="integration-content scrollbar">
                        <div className="container">
                            <div className="integration-body">
                            <h2 className='subtitle'>CRM Integration settings</h2>
                            <ul className="list-group-check">
                                <li className="list-group-check-item">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value=""  id="linkCrm"/>
                                        <label className="form-check-label" htmlFor="linkCrm">Link to CRM</label>
                                    </div>
                                    <ul className="list-group-check">
                                        <li className="list-group-check-item">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="processMsg" />
                                                <label className="form-check-label" htmlFor="processMsg">Process message for{'\xa0'.repeat(1)}
                                                    <Dropdown className="dropdown-link d-inline-block">
                                                        <Dropdown.Toggle variant="link" className='p-0'>week</Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            {['week', 'mount', 'all the time'].map((i) =>
                                                                <Dropdown.Item href="/" key={i}>{i}</Dropdown.Item>
                                                            )}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </label>
                                            </div>
                                        </li>
                                        <li className="list-group-check-item">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="createLead" />
                                                <label className="form-check-label" htmlFor="createLead">Create{'\xa0'.repeat(1)}
                                                    <Dropdown className="dropdown-link d-inline-block">
                                                        <Dropdown.Toggle variant="link" className='p-0'>Lead</Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            {['lead', 'customer'].map((i) =>
                                                                <Dropdown.Item href="/" key={i}>{i}</Dropdown.Item>
                                                            )}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    {'\xa0'.repeat(1)}for incoming messages from new email address
                                                </label>
                                            </div>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <div className="text-start m-l">
                            Lead and Customer source: <Dropdown className="dropdown-link d-inline-block">
                                <Dropdown.Toggle variant="link" className='p-0'>E-Mail</Dropdown.Toggle>
                                <Dropdown.Menu>
                                <div className='dropdown-body scrollbar mh-300 mx-5'>
                                    {['Call', 'E-Mail', 'Website', 'Advertising', 'Exiting Client', 'By Recommendation', 'Show / Exhibition', 'CRM from', 'Caliback', 'Sales boost'].map((i) =>
                                    <Dropdown.Item href="/" key={i}>{i}</Dropdown.Item>
                                    )}
                                </div>
                                </Dropdown.Menu>
                            </Dropdown>
                            </div>
                            </div>
                            <div className="btn-toolbar">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="mayLater"/>
                                <label className="form-check-label text-primary" htmlFor="mayLater">Maybe later</label>
                            </div>
                            <button className="btn btn-lg btn-secondary" onClick={(e)=>{setIntegrationStep('intStep3')}}>
                                <span className="text">Next</span>
                                <span className="icon"><SvgArrowRight className='icon-arrow-right' color='#000000' width="10" height='10' /></span>
                            </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            { integrationStep === 'intStep3' &&
                <div className="section-content section-integration">
                    <div className="integration-content scrollbar">
                        <div className="container">
                            <div className="integration-body mb-0">
                                <h2 className="subtitle">You are done</h2>
                                <figure><img src={Confetti} alt="Confetti" /></figure>
                                <p className="sub-lead">Your email address <br />successfully connected</p>
                            </div>
                            <div className="btn-toolbar justify-content-center">
                                <Link className="btn btn-lg btn-secondary" to="/email/inbox">Go to your Inbox</Link>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}


export default Integration;