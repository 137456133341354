import React, { useEffect, useRef, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import EmailTopbar from '../EmailTopbar/EmailTopbar'
import { useNavigate } from 'react-router';
import { Editor } from '@tinymce/tinymce-react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { accountLogOut, emailSettingSignature, getGoogleEmailsList } from '../../../api';
import { setAccountId, setEmailProvider, setEmailSettingData, setEmailsList } from '../../../action';

import { SvgDelete, SvgEmail, SvgRoundPlus } from '../../../utils/IconInfo';

const schema = yup.object().shape({
    userAccountId: yup.string()
        .when("submitType", {
            is: (value) => value === "disconnect",
            then: (schema) => schema.required("* This field is required"),
            otherwise: (schema) => schema.notRequired()
        }),
    fromEmail: yup.string()
        .when("submitType", {
            is: (value) => value === "save",
            then: (schema) => schema.required("* This field is required"),
            otherwise: (schema) => schema.notRequired()
        }),
    replyToEmail: yup.string()
        .when("submitType", {
            is: (value) => value === "save",
            then: (schema) => schema.required("* This field is required"),
            otherwise: (schema) => schema.notRequired()
        }),
})

const EmailSettings = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const editorRef = useRef();

    const [initLoad, setInitLoad] = useState(true)
    const [signContent, setSignContent] = useState("")
    const [userLoaded, setUserLoaded] = useState(false)
    const [submitType, setSubmitType] = useState("")

    const accountsList = useSelector((state) => state.reducer.emailsList)
    const emailProvider = useSelector((state) => state.reducer.emailProvider);
    const emailSettingData = useSelector((state) => state.reducer.emailSettingData);

    const { register, handleSubmit, setValue, formState: { errors } } = useForm({ resolver: yupResolver(schema) });

    const fetchEmailsList = async () => {
        try {
            setUserLoaded(false);
            const loggedAccountData = await getGoogleEmailsList();
            dispatch(setEmailsList(loggedAccountData));
            if (loggedAccountData.accounts.length > 0) {
                dispatch(setAccountId(loggedAccountData.accounts[0].account_id));
                setValue("userAccountId", loggedAccountData.accounts[0].account_id);
            } else {
                dispatch(setAccountId(0));
            }
            if (emailSettingData.fromEmail !== "") {
                setValue("fromEmail", emailSettingData.fromEmail);
            }
            if (emailSettingData.replyToEmail !== "") {
                setValue("replyToEmail", emailSettingData.replyToEmail);
            }            
            setUserLoaded(true);
        } catch (err) { console.error(err) }
    };

    const handleAccountId = (event) => {
        dispatch(setEmailProvider(emailProvider));
        if (accountsList.accounts.length > 0) {
            let signature = ""
            accountsList.accounts.map((item) => {
                if (item.account_id === event.target.value) {
                    signature = item.signature;
                }
            })
            setSignContent(!signature ? "" : signature);
        }
    }

    const onSubmit = async (data) => {
        if (data.submitType === "save") {
            await emailSettingSignature(editorRef.current.getContent() === "" ? null : editorRef.current.getContent(), data.userAccountId);
            fetchEmailsList();
            const emailSettingData = { 
                "fromEmail": data.fromEmail,
                "replyToEmail": data.replyToEmail,
            }

            dispatch(setAccountId(data.userAccountId));
            dispatch(setEmailSettingData(emailSettingData))

            navigate("/");
        } else if (data.submitType === "cancel") {
            navigate("/");
        } else if (data.submitType === "disconnect") {
            await accountLogOut(data.userAccountId)
            fetchEmailsList();
        }
    }

    useEffect(() => {
        fetchEmailsList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (userLoaded && initLoad) {
            if (accountsList.accounts.length > 0) {
                setSignContent(accountsList.accounts[0].signature)
                setInitLoad(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initLoad, userLoaded])

    useEffect(() => {
        if (submitType === "disconnect") {
            setValue("submitType", "disconnect");
        } else if (submitType === "save") {
            setValue("submitType", "save");
        } else if (submitType === "cancel") {
            setValue("submitType", "cancel");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitType])

    return (
        <LoadingOverlayWrapper active={!userLoaded} spinner text="loading">
            <div className="section-content section-settings">
                <div className="media-list media-email">
                    <EmailTopbar />
                    <div className="media-list-body scrollbar">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className='setings'>
                                <div className='setings-items'>
                                    <div className="btn btn-link link-black px-0 fw-semibold mb-5" style={{ width: "24%" }} >
                                        <SvgEmail className='icon icon-email' color="black" />
                                        <select className="form-select user-select" style={{ marginLeft: 10 }} {...register("userAccountId")} onChange={(e) => handleAccountId(e)}>
                                            <option value="" />
                                            {accountsList.accounts.map((item, index) => (
                                                <option key={index} value={item.account_id}>{item.email}</option>
                                            ))}
                                        </select>
                                    </div>
                                    {errors?.userAccountId && (
                                        <p style={{ color: "#F07D12", marginLeft: "50px", fontSize: "15px" }}>{errors?.userAccountId?.message}</p>
                                    )}
                                    <div className='list-button my-0'>
                                        <div className='list-item-button'>
                                            <a href='/#' className="btn btn-link px-0">
                                                <SvgRoundPlus className='icon icon-add-circle' color='#009BB0' />
                                                <span className='text'>Add another account</span>
                                            </a>
                                        </div>
                                        <div className='list-item-button'>
                                            {/* <div className="btn btn-link px-0"> */}
                                            <SvgDelete className='icon icon-delete' color='#009BB0' />
                                            <button type="submit" className='disconnect-btn' onClick={() => setSubmitType("disconnect")}>Disconnect</button>
                                            {/* </div> */}
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='setings-items d-none'>
                                    <h3>Email Tracking</h3>
                                    <div className="form-check mb-0">
                                        <input className="form-check-input" type="checkbox" value="" id="trackEmail" />
                                        <label className="form-check-label" htmlFor="trackEmail">Track opens and clicks</label>
                                    </div>
                                </div>
                                <div className='setings-items d-none'>
                                    <h3>CRM Settings</h3>
                                    <ul className="list-group-check">
                                        <li className="list-group-check-item">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="linkCrm" />
                                                <label className="form-check-label" htmlFor="linkCrm">Link to CRM</label>
                                            </div>
                                            <ul className="list-group-check">
                                                <li className="list-group-check-item">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="" id="processMsg" />
                                                        <label className="form-check-label" htmlFor="processMsg">Process message for <Dropdown className="dropdown-link d-inline-block">
                                                            <Dropdown.Toggle variant="link" className='p-0'>week</Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                {['week', 'mount', 'all the time'].map((i) =>
                                                                    <Dropdown.Item href="/" key={i}>{i}</Dropdown.Item>
                                                                )}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                        </label>
                                                    </div>
                                                </li>
                                                <li className="list-group-check-item">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="" id="createLead" />
                                                        <label className="form-check-label" htmlFor="createLead">Create <Dropdown className="dropdown-link d-inline-block">
                                                            <Dropdown.Toggle variant="link" className='p-0'>Lead</Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                {['lead', 'customer'].map((i) =>
                                                                    <Dropdown.Item href="/" key={i}>{i}</Dropdown.Item>
                                                                )}
                                                            </Dropdown.Menu>
                                                        </Dropdown> for incoming messages from new email address</label>
                                                    </div>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <p className="text-start m-l">
                                        Lead and contact source: <Dropdown className="dropdown-link d-inline-block">
                                            <Dropdown.Toggle variant="link" className='p-0'>E-Mail</Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <div className='dropdown-body scrollbar mh-300 mx-5'>
                                                    {['Call', 'E-Mail', 'Website', 'Advertising', 'Exiting Client', 'By Recommendation', 'Show / Exhibition', 'CRM from', 'Caliback', 'Sales boost'].map((i) =>
                                                        <Dropdown.Item href="/" key={i}>{i}</Dropdown.Item>
                                                    )}
                                                </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </p>
                                </div> */}
                                <div className='setings-items'>
                                    <h3>EMAIL ACCOUNTS</h3>
                                    <p>The default From and Reply-to emails help send and track replies in Sensei CRM. To receive replies in a different mailbox, change the Reply-to email.</p>
                                    <div className='row'>
                                        <div className='col-6 col-lg-3'>
                                            <div className='form-group-lg'>
                                                <label className="form-label">From email <span className="asterisk">*</span></label>
                                                <select className="form-select" {...register("fromEmail")}>
                                                    <option value="" />
                                                    {accountsList.accounts?.map((item, index) => (<option key={index} value={item.email}>{item.email}</option>))}
                                                </select>
                                                {errors?.fromEmail && (
                                                    <p style={{ color: "#F07D12", marginLeft: "10px", marginTop: "10px", fontSize: "15px" }}>{errors?.fromEmail?.message}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-6 col-lg-3'>
                                            <div className='form-group-lg mb-0'>
                                                <label className="form-label">Reply-to email <span className="asterisk">*</span></label>
                                                <select className="form-select" {...register("replyToEmail")}>
                                                    <option value="" />
                                                    {accountsList.accounts?.map((item, index) => (<option key={index} value={item.email}>{item.email}</option>))}
                                                </select>
                                                {errors?.replyToEmail && (
                                                    <p style={{ color: "#F07D12", marginLeft: "10px", marginTop: "10px", fontSize: "15px" }}>{errors?.replyToEmail?.message}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className='setings-items d-none'>
                                    <h3>Email Tracking Notifications</h3>
                                    <ul className='list-group-check'>
                                        <li className="list-group-check-item">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" name="Notifications" id="recipientReplies" />
                                                <label className="form-check-label" htmlFor="recipientReplies">Get notification when a recipient replies to email </label>
                                            </div>
                                        </li>
                                        <li className="list-group-check-item">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" name="Notifications" id="recipientOpens" />
                                                <label className="form-check-label" htmlFor="recipientOpens">Get notification when recipient opens email </label>
                                            </div>
                                        </li>
                                        <li className="list-group-check-item">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" name="Notifications" id="recipientClick" />
                                                <label className="form-check-label" htmlFor="recipientClick">Get notification when recipient click on a link in your email </label>
                                            </div>
                                        </li>
                                    </ul>
                                </div> */}
                                <div className='setings-items'>
                                    <h3>EMAIL SIGNATURE</h3>
                                    <div className='note-editor row'>
                                        <div className='col-9'>
                                            <div className='note-editer-area'>
                                                <Editor
                                                    apiKey='izm99hun14ap8uaosl8mlluhbz6gmgbpt2b32ty5fpjitz1f'
                                                    onInit={(evt, editor) => { editorRef.current = editor }}
                                                    initialValue={signContent}
                                                    init={{
                                                        height: 300,
                                                        menubar: false,
                                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',

                                                        selector: 'textarea#premiumskinsandicons-material-classic',
                                                        skin: 'material-classic',
                                                        content_css: 'material-classic',
                                                        icons: 'material',
                                                        plugins: 'tinydrive advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code help wordcount',
                                                        toolbar: 'fontsize | fontfamily | align | numlist | bold italic underline link | undo redo',
                                                    }}
                                                />
                                            </div>
                                            <div className='note-editor-action'>
                                                <div className="note-editor-action-left">
                                                    <div className='note-editor-action-item'>
                                                        <button type='submit' className="btn btn-link pl-0" onClick={() => setSubmitType("cancel")}>Cancel</button>
                                                    </div>
                                                    <div className='note-editor-action-item'>
                                                        <button type='submit' className="btn btn-lg btn-secondary" onClick={() => setSubmitType("save")}>Save</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </LoadingOverlayWrapper>
    );
}

export default EmailSettings;