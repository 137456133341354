import moment from 'moment';

export const formatDate = (dateString) => {
  const currentDate = new Date()
  const emailDate = new Date(dateString)

  if (emailDate.getFullYear() === currentDate.getFullYear() && emailDate.getMonth() === currentDate.getMonth() && emailDate.getDay() === currentDate.getDay()) {
    return moment(dateString).format('hh : mm a');
  } else if (emailDate.getFullYear() === currentDate.getFullYear()) {
    return moment(dateString).format('DD MMM');
  } else {
    return moment(dateString).format('DD MMM YYYY');
  }
};

export const getUserId = () => {
  return localStorage.getItem("user-id");
}

export const setUserId = (userId) => {
  return localStorage.setItem("user-id", userId);
}

export const getEmailProvider = () => {
  return localStorage.getItem("email-provider");
}

export const setEmailProvider = (provider) => {
  return localStorage.setItem("email-provider", provider);
}