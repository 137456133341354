import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import { setAccountId, setEmailsList, setIsEmptyAccount, sidebarExpanded } from '../../action/index'
import { SvgArrowDown, SvgDashboard, SvgEmail, SvgLogo, SvgMenuCollapse, SvgMenuExpand, SvgSettings } from '../../utils/IconInfo';

import './style.scss';
import { getGoogleEmailsList } from '../../api';
import Toast from '../Modals/Toast';

const EmailHeader = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const [menuCollapse, setMenuCollapse] = useState(true)
    const [isShowToast, setIsShowToast] = useState(false)

    const accountId = useSelector((state) => state.reducer.account_id);

    const fetchEmailsList = async () => {
        try {
            const emailsList = await getGoogleEmailsList();
            if (emailsList.accounts.length > 0) {
                dispatch(setEmailsList(emailsList));
                dispatch(setAccountId(emailsList.accounts[0].account_id));
            }
        } catch (err) {
            console.error(err)
        }
    };


    const emailboxOpenHandle = async () => {
        if (accountId === 0) {
            setIsShowToast(true)
        }
    };

    if (accountId === 0) {
        fetchEmailsList();
    }

    return (
        <div className="sidebar sidebar-icons-left">
            <Toast isShowToast={isShowToast} setIsShowToast={setIsShowToast} />
            <header className="sidebar-header">
                <a className='logo-icon' href="/">
                    <SvgLogo color="white" />
                </a>
            </header>
            <nav className="sidebar-navigation">
                <ul className="menu">
                    <li className="menu-item">
                        <button type='button' onClick={() => { dispatch(sidebarExpanded('sidebar-collapse')); setMenuCollapse(!menuCollapse) }} className="menu-link menu-collapse-icon">
                            {menuCollapse ? <SvgMenuCollapse className="icon icon-collapse-menu" color="white" />
                                : <SvgMenuExpand className="icon icon-expand-menu" color="white" />}
                            <span className='text'>Expand menu</span>
                        </button>
                    </li>
                    <li className="menu-item">
                        <Link to='/' className={location.pathname === '/' ? 'menu-link active' : 'menu-link'}>
                            <SvgDashboard className="icon icon-dashboard" color={location.pathname === '/' ? "#F07D12" : "white"} />
                            <span className='text'>Dashboard</span>
                        </Link>
                    </li>
                    {/* <li className="menu-item d-none">
                        <ul>
                            <li className="menu-item">
                                <button type='button' className="menu-link">
                                    <span className="icon icon-contacts"></span>
                                    <span className='text'>Contacts</span>
                                    <div className='icon text icon-dropDown' >
                                        <img alt='message' width={13} height={7} src={SvgArrowDown} />
                                    </div>
                                </button>
                            </li>
                        </ul>
                        <Link to='/' className="menu-link">
                            <span className="icon icon-contacts"></span>
                            <span className='text'>Contacts</span>
                        </Link>
                    </li>
                    <li className="menu-item d-none">
                        <Link to='/' className="menu-link">
                            <span className="icon icon-leads"></span>
                            <span className='text'>Leads</span>
                        </Link>
                    </li>
                    <li className="menu-item d-none">
                        <Link to='/' className="menu-link">
                            <span className="icon icon-orders"></span>
                            <span className='text'>Orders</span>
                        </Link>
                    </li>
                    <li className="menu-item d-none">
                        <Link to='/' className="menu-link">
                            <span className="icon icon-tasks"></span>
                            <span className='text'>Tasks</span>
                        </Link>
                    </li> */}
                    <li className="menu-item">
                        <Link to={accountId !== 0 ? "/email/inbox" : "/"} className={location.pathname.includes('/email') && location.pathname !== "/email/settings" ? 'menu-link active' : 'menu-link'} onClick={() => emailboxOpenHandle()}>
                            <SvgEmail className="icon icon-email" color={location.pathname.includes('/email') && location.pathname !== "/email/settings" ? "#F07D12" : "white"} />
                            <span className='text'>Emails</span>
                        </Link>
                    </li>
                    {/* <li className="menu-item d-none">
                        <Link to='/' className="menu-link">
                            <span className="icon icon-reports"></span>
                            <span className='text'>Reports</span>
                        </Link>
                    </li>
                    <li className="menu-item d-none">
                        <Link to='/' className="menu-link">
                            <span className="icon icon-hrt-menu"></span>
                            <span className='text'>Other</span>
                        </Link>
                    </li> */}
                </ul>
                <ul className="menu menu-down">
                    {/* <li className="menu-item d-none">
                        <Link to='/' className="menu-link">
                            <div className='icon' >
                                <img alt='message' src={SvgMessage} />
                            </div>
                            <span className='text'>Chat</span>
                        </Link>
                    </li> */}
                    <li className="menu-item">
                        <button type='button' className={location.pathname === '/email/settings' ? 'menu-link active' : 'menu-link'} onClick={() => { accountId !== 0 ? navigate("/email/settings") : navigate("/"); emailboxOpenHandle() }}>
                            <SvgSettings className="icon icon-settings" color="white" />
                            <span className='text'>Settings</span>
                            {!menuCollapse && <SvgArrowDown className='icon icon-arrow-down' color="white" />}
                        </button>
                    </li>
                </ul>
            </nav>
        </div>
    );
}


export default EmailHeader;