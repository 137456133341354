import React from 'react'
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { setIsDeleteConfirmModal } from '../../action';
import { SvgClose, SvgModalClose } from '../../utils/IconInfo';

const DeleteConfirmModal = (props) => {
  const dispatch = useDispatch()

  const isDeleteConfirmModal = useSelector((state) => state.reducer.isDeleteConfirmModal);

  return (
    <Modal
      show={isDeleteConfirmModal}
      onHide={() => { }}
      backdrop="static"
      keyboard={false}
      size="md"
      centered
    >
      <Modal.Body>
        <div className='modal-title-section'>
          <div className='modal-title-text'>Confirm</div>
          <div className='modal-close-btn' onClick={() => { dispatch(setIsDeleteConfirmModal(false)) }}>
            <SvgModalClose className='icon-btn' color='#373435' />
          </div>
        </div>
        <div className='modal-content-section'>
          Are you sure you want to delete this email?<br />
          (Passt, in case you change your mind, you can find this email in your Trash.)
        </div>
        <div className="model-button-section">
          <button className="modal-button" onClick={() => { dispatch(setIsDeleteConfirmModal(false)) }}>No</button>
          <button className="modal-button modal-yes-button" onClick={() => { dispatch(setIsDeleteConfirmModal(false)); props.onModalOk() }}>Yes</button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default DeleteConfirmModal