import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
// import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { useDispatch, useSelector } from "react-redux";
import { Editor } from '@tinymce/tinymce-react';
import { useForm } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import LoadingOverlay from 'react-loading-overlay-ts';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { draftReplySave, draftSave, getBlobFromUrl, sendEmail, uploadAttachmentFile } from "../../../api/index.js";
import { getEmailProvider } from "../../../api/helper.js";
// import { handleCommonModal } from "../../../action/index.js";

import AddTaskModal from '../../Modals/AddTaskModal';

import { SvgArrowDown, SvgDelete, SvgForwardMsg, SvgReplyMsg } from '../../../utils/IconInfo.js';
import MediaFiles from '../MediaFiles/MediaFiles.js';

const isEmailValidate = (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
const schema = yup.object().shape({
  // from: yup.string()
  //   .email('* Invalid email address')
  //   .required('* This field is required'),
  to: yup.string()
    .when("submitType", {
      is: (value) => value === "send",
      then: (schema) => schema.required("* This field is required"),
      otherwise: (schema) => schema.notRequired()
    })
    .test(
      'emails',
      '* Invalid email address',
      (value) => value ? value.split(',').every(isEmailValidate) : true
    ),
  cc: yup.string()
    .test(
      'emails',
      '* Invalid email address',
      (value) => value ? value.split(',').every(isEmailValidate) : true
    ),
  bcc: yup.string()
    .test(
      'emails',
      '* Invalid email address',
      (value) => value ? value.split(',').every(isEmailValidate) : true
    )
});
const useOutSideDetect = (ref, setIsShowReplyDropdownMenu) => {
  useEffect(() => {
      const handleClickOutSide = (event) => {
          if (ref.current && !ref.current.contains(event.target)) {
              setIsShowReplyDropdownMenu(false);
          }
      }
      document.addEventListener('mousedown', handleClickOutSide);
      return () => {
          document.removeEventListener('mousedown', handleClickOutSide);
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref])
  
}

const Compose = ({ composeType, setComposeType, thread, messageIndex }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const editorRef = useRef(null);
  const quickMenuRef = useRef(null)

  const [composeCategory, setComposeCategory] = useState(null)
  const [isShowReplyDropdownMenu, setIsShowReplyDropdownMenu] = useState(false)
  const [isShowCc, setShowCc] = useState(false);
  const [isShowBcc, setShowBcc] = useState(false);
  const [emailContent, setEmailContent] = useState('');
  const [signature, setSignature] = useState("");
  const [savedAttBlob, setSavedAttBlob] = useState([]);
  const [files, setFiles] = useState([]);
  const [submitType, setSubmitType] = useState(null);
  const [btnDisabled, setBtnDisabled] = useState(false);
  // const [track, setTrack] = useState(false);

  const props = useSelector((state) => state.reducer);
  const accountId = useSelector((state) => state.reducer.account_id);
  const currentEmailBox = useSelector((state) => state.reducer.currentEmailBox);

  const { register, setValue, reset, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(schema) });

  useOutSideDetect(quickMenuRef, setIsShowReplyDropdownMenu);

  const send = async (data) => {
    let messageId;
    let messageRes;
    let attachmentsRes;
    let fileNameList = [];

    if (composeType === 'new') {
      messageRes = await draftSave(null, accountId, data, files);
      messageId = messageRes.id;
    } else if (composeType === 'draft') {
      messageId = thread[messageIndex]?.id
      await draftSave(messageId, accountId, data, files);
    } else {
      messageRes = await draftReplySave(composeType, thread[messageIndex].id, accountId, data, files);
      messageId = messageRes.id;
    }
    if (files.length > 0) {
      attachmentsRes = await uploadAttachmentFile(messageId, accountId, files)
      attachmentsRes.map((item) => {
        fileNameList.push({ name: item.filename })
      })
      data['attachments'] = fileNameList;
    }
    await sendEmail(messageId, accountId, data);
    setBtnDisabled(false);
    navigate(`/email/${currentEmailBox}`);
  }
  const saveDraft = async (data) => {
    console.log('save-data: ', data);

    let messageId;
    let messageRes;

    if (composeType === 'new') {
      messageRes = await draftSave(null, accountId, data, files);
      messageId = messageRes.id;
    } else if (composeType === 'draft') {
      messageId = thread[messageIndex]?.id
      await draftSave(messageId, accountId, data, files);
    } else {
      messageRes = await draftReplySave(composeType, thread[messageIndex].id, accountId, data, files);
      messageId = messageRes.id;
    }
    if (files.length > 0) {
      await uploadAttachmentFile(messageId, accountId, files);
    }
    setBtnDisabled(false);
    navigate(`/email/${currentEmailBox}`);
  }
  const onTxtCcBlur = (event) => {
    if (event.target.value.trim() === '')
      setShowCc(false);
  }
  const onTxtBccBlur = (event) => {
    if (event.target.value.trim() === '')
      setShowBcc(false);
  }
  const onDrop = useCallback(acceptedFiles => {
    setFiles(prev => [...prev, ...acceptedFiles]);
  }, []);
  const onRemoveFile = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };
  const convertSavedAttToBlob = async () => {
    const blobs = await Promise.all(
      thread[messageIndex]?.attachments.map(async (item) => {
        const myBlob = await getBlobFromUrl(accountId, item.download_url)
        return new File([myBlob], item.name, { type: myBlob.type });
      })
    )
    setSavedAttBlob(blobs);
  };

  const { getRootProps, getInputProps, isDragActive, } = useDropzone({ multiple: true, onDrop });

  const onSubmit = (data) => {
    console.log('submit-data: ', data);

    const emailData = {};

    if (!!data.to) {
      emailData['to'] = data.to.split(",");
    }
    if (!!data.cc) {
      emailData['cc'] = data.cc.split(",");
    }
    if (!!data.bcc) {
      emailData['bcc'] = data.bcc.split(",");
    }
    if (!!data.subject) {
      emailData['subject'] = data.subject;
    }
    if (!!editorRef?.current?.getContent()) {
      emailData['body'] = editorRef.current.getContent();
    }

    if (submitType === true) {
      saveDraft(emailData);
    } else if (submitType === false) {
      emailData['track'] = true;
      send(emailData);
    }
    setBtnDisabled(true)
  }

  //composer category : true -> (new, a draft) / false -> (reply, replyToAll, forward)  
  useEffect(() => {
    if (composeType === 'new' || composeType === 'draft') {
      setComposeCategory(true);
    } else {
      setComposeCategory(false);
    }
  }, [composeType])
  
  //set "submitType" : save / send
  useEffect(() => {
    if (submitType) {
      setValue('submitType', 'save');
    } else if (!submitType) {
      setValue('submitType', 'send');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitType])

  //set attachments
  useEffect(() => {
    if (savedAttBlob?.length > 0) {
      setFiles(savedAttBlob);
    }
  }, [savedAttBlob]);

  //set init value for input fields
  useEffect(() => {
    let fromEmail = '';
    let toEmail = '';
    let ccEmail = '';
    let bccEmail = '';
    let emailSubject = '';

    //set "from-email" and "signature" on editor content
    for (let i = 0; i < props.emailsList.accounts.length; i++) {
      if (props.emailsList.accounts[i].provider === getEmailProvider() && props.emailsList.accounts[i].account_id === accountId) {
        fromEmail = props.emailsList.accounts[i].email;
        if (!!props.emailsList.accounts[i].signature) {
          setSignature(props.emailsList.accounts[i].signature);
        } else {
          setSignature('');
        }
      }
    }
    
    if (composeType === 'new') {
      reset({
        from: fromEmail,
        to: toEmail,
        cc: ccEmail,
        bcc: bccEmail,
        subject: emailSubject,
      })
    } else if (thread) {
      if (composeType === 'draft') {
        if (!!thread[messageIndex].to[0].email && !!thread[messageIndex].to[0].name) {
          toEmail = thread[messageIndex].to.map((item) => (item.email)).join(",");
        }
        if (!!thread[messageIndex].cc[0].email && !!thread[messageIndex].cc[0].name) {
          ccEmail = thread[messageIndex].cc.map((item) => (item.email)).join(",");
          setShowCc(true);
        }
        if (!!thread[messageIndex].bcc[0].email && !!thread[messageIndex].bcc[0].name) {
           bccEmail = thread[messageIndex].bcc.map((item) => (item.email)).join(",");
           setShowBcc(true);
        }
        if (!!thread[messageIndex].subject) {
          emailSubject = thread[messageIndex].subject;
        }
        if (thread[messageIndex].body) {
          setEmailContent(thread[messageIndex].body)
          // dispatch(setEditorContent(thread[messageIndex].body));
        }      
        if (thread[messageIndex].attachments.length > 0) {
          convertSavedAttToBlob();
        }
      } else if (composeType === 'reply') {
        if (!!thread[messageIndex].from.email) {
          toEmail = thread[messageIndex].from.email;
          console.log('thread: ', thread);
        } else if (!!thread[messageIndex].from.name) {          
          toEmail = thread[messageIndex].from.email;
        }
        setEmailContent('');
      } else if (composeType === 'replyToAll') {        
        if (!!thread[messageIndex].from.email) {
          toEmail = thread[messageIndex].from.email;
        } else if (!!thread[messageIndex].from.name) {          
          toEmail = thread[messageIndex].from.email;
        }
        if (!!thread[messageIndex].cc[0].email) {
          ccEmail = thread[messageIndex].cc.map((item) => (item.email)).join(",")
        }
        if (!!thread[messageIndex].bcc[0].email) {
          bccEmail = thread[messageIndex].bcc.map((item) => (item.email)).join(",")
        }
        if (!!thread[messageIndex].cc[0].email || !!thread[messageIndex].cc[0].name) {
          setShowCc(true);
        }
        if (!!thread[messageIndex].bcc[0].email || !!thread[messageIndex].bcc[0].name) {
          setShowBcc(true);
        }
        setEmailContent('');
      } else if (composeType === 'forward') {        
        if (!!thread[messageIndex].subject) {
          emailSubject = thread[messageIndex].subject;
        }
        if (!!thread[messageIndex].body) {
          setEmailContent(thread[messageIndex].body)
        }
        if (thread[messageIndex].attachments.length > 0) {
          convertSavedAttToBlob();
        }
      } 
      
      reset({
        from: fromEmail,
        to: toEmail,
        cc: ccEmail,
        bcc: bccEmail,
        subject: emailSubject,
      })
      
    }
  }, [accountId, composeType, props.emailsList.accounts, thread])

  return (
    <LoadingOverlay active={btnDisabled} spinner>
      <div className="section-content section-compose">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={composeCategory ? 'compose scrollbar' : 'reply-compose'}>
            {composeCategory &&
              <div className='compose-header'>
                <div className="input-group compose-from">
                  <span className="input-group-text">From</span>
                  <input type="text" className="form-control" readOnly={true} {...register("from")} />
                </div>
                <div className="input-group compose-to">
                  <span className="input-group-text">To</span>
                  <input type="text" className="form-control " style={{ borderRight: "none" }} placeholder='Enter recipients' {...register("to")} />

                  {!isShowCc ? <button type='button' className="input-group-text border-end-0 link-gray" onClick={() => { setShowCc(true) }}>Cc</button> : null}
                  {!isShowBcc ? <button type='button' className="input-group-text link-gray" onClick={() => { setShowBcc(true) }}>Bcc</button> : null}
                </div>
                {errors?.to && (
                  <p style={{ color: "red", marginLeft: "80px", fontSize: "15px" }}>{errors?.to?.message}</p>
                )}

                {isShowCc ?
                  <div className="input-group compose-cc">
                    <span className="input-group-text">Cc</span>
                    <input type="text" className="form-control" placeholder='Enter CC' {...register("cc")} onBlur={(event) => onTxtCcBlur(event)} />
                  </div> : null}
                {errors?.cc && (
                  <p style={{ color: "red", marginLeft: "80px", fontSize: "15px" }}>{errors?.cc?.message}</p>
                )}
                {isShowBcc ?
                  <div className="input-group compose-bcc">
                    <span className="input-group-text">Bcc</span>
                    <input type="text" className="form-control" placeholder='Enter BCC' {...register("bcc")} onBlur={(event) => onTxtBccBlur(event)} />
                  </div> : null}
                {errors?.bcc && (
                  <p style={{ color: "red", marginLeft: "80px", fontSize: "15px" }}>{errors?.bcc?.message}</p>
                )}

                <div className="input-group compose-subject">
                  <span className="input-group-text">Subject</span>
                  <input type="text" className="form-control" style={{ borderRight: "none" }} placeholder='Write a subject' {...register("subject")} />
                  <Dropdown className="dropdown-placehoder">
                    <Dropdown.Toggle variant="outline-platinum-dark" className='text-black border-start-0 xborder-end-0'>Placeholder</Dropdown.Toggle>
                    <Dropdown.Menu className='bg-white'>
                      <div className='dropdown-body scrollbar'>
                        <Dropdown.Header>
                          <div className='form-group mb-0'><input type="text" className="form-control" /></div>
                        </Dropdown.Header>
                        <Dropdown.ItemText className='fw-semibold'>Customer fields</Dropdown.ItemText>
                        {['Full name', 'First name', 'Last name', 'Accounts', 'Emails'].map((i) =>
                          <Dropdown.Item as="button" key={i}>{i}</Dropdown.Item>
                        )}
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                  {/* <Dropdown className="dropdown-template d-none" autoClose="outside">
                  <Dropdown.Toggle variant="outline-platinum-dark" className='text-black border-start-0'>Templates</Dropdown.Toggle>
                  <Dropdown.Menu className='bg-white'>
                    <div className='dropdown-body scrollbar'>
                      <Dropdown.Header>
                        <div className='form-group mb-0'><input type="text" className="form-control" placeholder='Search by tags and templates' /></div>
                      </Dropdown.Header>
                      <Dropdown.ItemText className='text-uppercase'>TAGS</Dropdown.ItemText>
                      <Dropdown.Divider />
                      <Dropdown.ItemText className='text-silver-sand'>No tags found.</Dropdown.ItemText>
                      <Dropdown.ItemText className='text-uppercase'>TEMPLATES</Dropdown.ItemText>
                      <Dropdown.Divider />
                      {['Collect Customer Reviews', 'Building Quote to Customer Without Price', 'Unpaid Invoice PDF to Customer '].map((i) =>
                        <Dropdown.Item href="/#" key={i}>{i}</Dropdown.Item>
                      )}
                      <Dropdown.Divider />
                      <Dropdown.ItemText>
                        <div className='btn btn-link p-0'>
                          <span className='icon f-18'><i className='icon-add-circle'></i></span>
                          <span className='text'>Create template</span>
                        </div>
                      </Dropdown.ItemText>
                    </div>
                  </Dropdown.Menu>
                </Dropdown> */}
                </div>
                {errors?.subject && (
                  <p style={{ color: "red", marginLeft: "80px", fontSize: "15px" }}>{errors?.subject?.message}</p>
                )}

              </div>
            }
            <div className='compose-body'>
              <div className='"note-editor'>

                {composeCategory ?
                  <div className='note-editer-area'>
                    <Editor
                      apiKey='izm99hun14ap8uaosl8mlluhbz6gmgbpt2b32ty5fpjitz1f'
                      onInit={(evt, editor) => editorRef.current = editor}
                      initialValue={composeType === 'new' ? signature : emailContent}
                      init={{
                        height: 500,
                        menubar: false,
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }',
                        toolbar_location: 'top',

                        selector: 'textarea#premiumskinsandicons-material-classic',
                        skin: 'material-classic',
                        content_css: 'material-classic',
                        icons: 'material',
                        plugins: 'tinydrive advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code help wordcount',
                        toolbar: 'fontsize | fontfamily | align | numlist | bold italic underline link | undo redo',

                        statusbar: false
                      }}
                    />
                    <div className="attachments-container">
                      <section className='attachments-section'>
                        <input {...getInputProps()} />
                        <br />
                        <div {...getRootProps()} className='explain-section'>
                          {composeType !== 'reply' && <h6>Upload your file here</h6>}
                          {isDragActive ?
                            <p>(Drop the files here ...)</p> :
                            <p>(Drag and drop some files here, or click to select files)</p>
                          }
                        </div>
                      </section>
                      <div className='list-group-media'>
                        {files.length > 0 && <MediaFiles messageId={composeType === 'new' ? null : thread[messageIndex]?.id} attachments={files} onRemoveFile={() => onRemoveFile()} />}
                      </div>
                    </div>
                  </div>

                  :
                  
                  <div className='note-editer-area-simple'>
                    <div className='input-reply-section'>
                      <div className='reply-dropdown-button' onClick={() => setIsShowReplyDropdownMenu(true)}>
                        {composeType === 'forward'?
                          <SvgForwardMsg color='#373435' />
                          :
                          <SvgReplyMsg color='#373435' />
                        }
                        <SvgArrowDown color='#373435' />
                      </div>
                      {isShowReplyDropdownMenu &&
                        <div ref={quickMenuRef} className='reply-option-section'>
                            <div onClick={(e) => { e.stopPropagation(); setComposeType('reply'); setIsShowReplyDropdownMenu(false) }}>
                                <SvgReplyMsg color='#373435' />
                                <span>Reply</span>
                            </div>
                            <div onClick={(e) => { e.stopPropagation(); setComposeType('replyToAll'); setIsShowReplyDropdownMenu(false) }}>
                                <SvgReplyMsg color='#373435' />
                                <span>Reply to All</span>
                            </div>
                            <div onClick={(e) => { e.stopPropagation(); setComposeType('forward'); setIsShowReplyDropdownMenu(false) }}>
                                <SvgForwardMsg color='#373435' />
                                <span>Forward</span>
                            </div>
                        </div>
                      }
                      <input type="text" className="input-reply-to" {...register("from")} />
                    </div>
                    {(composeType === 'reply' || composeType === 'replyToAll') &&
                      <Editor
                        apiKey='izm99hun14ap8uaosl8mlluhbz6gmgbpt2b32ty5fpjitz1f'
                        onInit={(evt, editor) => editorRef.current = editor}
                        initialValue={signature}
                        init={{
                          height: 150,
                          menubar: false,
                          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }',
                          toolbar_location: 'bottom',

                          selector: 'textarea#premiumskinsandicons-material-classic',
                          skin: 'material-classic',
                          content_css: 'material-classic',
                          icons: 'material',
                          plugins: 'tinydrive advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code help wordcount',
                          toolbar: 'fontsize | fontfamily | align | numlist | bold italic underline link | undo redo',

                          statusbar: false
                        }}
                      />
                    }
                    {(composeType === 'forward') &&
                      <Editor
                        apiKey='izm99hun14ap8uaosl8mlluhbz6gmgbpt2b32ty5fpjitz1f'
                        onInit={(evt, editor) => editorRef.current = editor}
                        initialValue={emailContent}
                        init={{
                          height: 200,
                          menubar: false,
                          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }',
                          toolbar_location: 'bottom',

                          selector: 'textarea#premiumskinsandicons-material-classic',
                          skin: 'material-classic',
                          content_css: 'material-classic',
                          icons: 'material',
                          plugins: 'tinydrive advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code help wordcount',
                          toolbar: 'fontsize | fontfamily | align | numlist | bold italic underline link | undo redo',

                          statusbar: false
                        }}
                      />
                    }
                    <div className="attachments-container">
                      <section className='attachments-section'>
                        <input {...getInputProps()} />
                        <br />
                        <div {...getRootProps()} className='explain-section'>
                          {isDragActive ?
                            <p>(Drop the files here ...)</p> :
                            <p>(Drag and drop some files here, or click to select files)</p>
                          }
                        </div>
                      </section>
                      <div className='list-group-media'>
                        {files.length > 0 && <MediaFiles messageId={composeType === 'new' ? null : thread[messageIndex]?.id} attachments={files} onRemoveFile={() => onRemoveFile()} />}
                      </div>
                    </div>
                  </div>
                }
                <div className='note-editor-action justify-content-end'>
                  {/* <div className="note-editor-action-left d-none">
                    <div className='note-editor-action-item'>
                      <div className="form-check mb-0">
                        <input className="form-check-input" type="checkbox" value="" id='trackEmail' onChange={(event) => setTrack(event.target.checked)} />
                        <label className="form-check-label text-primary" htmlFor='trackEmail'>Track this email <i className="icon-question-cirlcle f-16 link-trolley-gray"></i></label>
                      </div>
                    </div>

                    <div className='note-editor-action-item'>
                      <span className="divider-line"></span>
                    </div>

                    <div className='note-editor-action-item'>
                      <button className="btn btn-link px-0" onClick={(e) => { dispatch(handleCommonModal('taskModal')) }}>
                        <span className='icon f-18 link-gray'><i className="icon-add-circle"></i></span>
                        <span className='text'>Add task</span>
                      </button>
                    </div>

                    <div className='note-editor-action-item'>
                      <span className="divider-line"></span>
                    </div>

                    <div className='note-editor-action-item'>
                      <Dropdown className='dropdown-icon'>
                        <Dropdown.Toggle variant="transparent" className='btn-link link-gray'><i className="icon-ver-menu"></i></Dropdown.Toggle>
                        <Dropdown.Menu className='mw-260 bg-white'>
                          <Dropdown.ItemText>
                            <div className="form-check mb-0">
                              <input className="form-check-input" type="checkbox" id='linkLead' defaultChecked={true} />
                              <label className="form-check-label" htmlFor='linkLead'>Link conversation to lead</label>
                            </div>
                          </Dropdown.ItemText>
                          <Dropdown.ItemText>
                            <div className="form-check mb-0">
                              <input className="form-check-input" type="checkbox" id='linkCustomer' defaultChecked={true} />
                              <label className="form-check-label" htmlFor='linkCustomer'>Link conversation to customer</label>
                            </div>
                          </Dropdown.ItemText>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div> */}
                  <div className="note-editor-action-right">
                    <div className='note-editor-action-item'>
                      <button className="btn btn-link link-gray px-0" onClick={() => { composeCategory ? navigate(`/email/${currentEmailBox}`) : setComposeType('none')}} disabled={btnDisabled} >
                        <SvgDelete className='icon icon-delete' color='#5F6267' />
                      </button>
                    </div>
                    <div className='note-editor-action-item'>
                      <span className="divider-line"></span>
                    </div>
                    <div className='note-editor-action-item'>
                      <button type='submit' onClick={() => { setSubmitType(true) }} disabled={btnDisabled} className="btn btn-link pl-0">Save as draft</button>
                    </div>
                    <div className='note-editor-action-item'>
                      <button type='submit' onClick={() => { setSubmitType(false) }} disabled={btnDisabled} className="btn btn-lg btn-secondary">Send</button>
                      {/* <Dropdown as={ButtonGroup} className="dropdown-split d-none">
                        <Dropdown.Toggle split variant="secondary" className="btn-lg split-divider" />
                        <Dropdown.Menu className='bg-white'>
                          <Dropdown.Item href="#/" onClick={(e) => { dispatch(handleCommonModal('sendLaterModal')) }}>
                            <span className='icon'><i className='icon-send-later'></i></span>
                            <span className='text bg-white'>Send Later</span>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <AddTaskModal />
    </LoadingOverlay>
  );
}


export default Compose;