import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import { useSelector } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";
import LoadingOverlay from 'react-loading-overlay-ts';

import { batchDeleteEmail, batchTrashEmail, getGoogleThread } from "../../../api/index.js";

import EmailTopbar from '../EmailTopbar/EmailTopbar';
import MessageItem from './MessageItem/index.jsx';
import Compose from '../Compose/Compose.js';

const Thread = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { threadId } = useParams();

    const [thread, setThread] = useState(null);
    console.log('thread: ', thread);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [userEmail, setUserEmail] = useState('');

    const accountId = useSelector((state) => state.reducer.account_id);
    const accountsList = useSelector((state) => state.reducer.emailsList);
    const emailBoxMenuLoaded = useSelector((state) => state.reducer.emailBoxMenuLoaded);
    const currentEmailBox = useSelector((state) => state.reducer.currentEmailBox);

    const fetchData = async () => {
        setDataLoaded(false);
        try {
            const googleThreadResponse = await getGoogleThread(threadId, accountId);
            if (googleThreadResponse.length > 0)
                setThread(googleThreadResponse);
            else
                setThread(null);
        } finally {
            setDataLoaded(true);
        }
    };
    const createMarkup = (message) => {
        return { __html: message.body };
    }
    const onDelete = async () => {
        if (threadId !== null) {
            try {
                if (location.pathname.box === "trash") {
                    await batchDeleteEmail([threadId], accountId);
                } else {
                    await batchTrashEmail([threadId], accountId);
                }
            } catch (err) {
                // error handling
            } finally {
                navigate(`/email/${currentEmailBox}`);
            }
        }

    }

    // get email data (thread)
    useEffect(() => {
        if (threadId !== null)
            fetchData();
        else
            fetchData().catch(console.error);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [threadId, emailBoxMenuLoaded]);
    
    useEffect(() => {
        if (dataLoaded) {
            if (accountId !== 0 && accountsList.accounts.length > 0) {
                accountsList.accounts.map((item) => { if (item.account_id === accountId) setUserEmail(item.email) });
            }
        }
    }, [accountId, dataLoaded, accountsList.accounts])    

    return (
        <LoadingOverlay
            active={!dataLoaded}
            spinner
            text='Loading ...'
        >
            <div className="section-content">
                <div className="media-list media-email">
                    <EmailTopbar onDelete={() => onDelete()} box={currentEmailBox} />
                    <div className="media-list-body scrollbar">
                        <div className='media-title-header border-bottom-0'><strong className='title'>Conversations </strong></div>
                        {!dataLoaded ?
                            <BeatLoader color="#36d7b7" />
                            :
                            thread !== null ?
                                thread.length === 1 && thread[0].id[0] === 'r' ?
                                    <Compose  composeType='draft' setComposeType={null} thread={thread} messageIndex={0} />
                                    :
                                    <Accordion defaultActiveKey={[thread[thread.length - 1].id]} alwaysOpen className='accordion-default'>
                                        {thread.map((message, index) => {
                                            return (
                                                <MessageItem 
                                                    key={index}
                                                    index={index}
                                                    eventKey={message.id}
                                                    thread={thread}
                                                    message={message}
                                                    userEmail={userEmail}
                                                    createMarkup={createMarkup}
                                                />
                                            )
                                        }
                                        )}
                                    </Accordion>
                                :
                                null
                        }                        
                    </div>
                </div>
            </div>
        </LoadingOverlay>
    );
}


export default Thread;