import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";

import Modal from 'react-bootstrap/Modal';
import { handleCloseModal } from "../../action/index.js";

const SendLaterModal = () => {

    const dispatch = useDispatch();
    const isShowSendLaterModal = useSelector((state) => state.reducer.isShowSendLaterModal);

    const [isEnableSelectDate, setIsEnableSelectDate] = useState(false);
    const [currentSelectOption, setCurrentSelectOption] = useState(null);

    useEffect(() => {
        if (currentSelectOption !== null && isEnableSelectDate === true) {
            setIsEnableSelectDate(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSelectOption]);

    useEffect(() => {
        if (currentSelectOption !== null) {
            setCurrentSelectOption(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEnableSelectDate]);

    const sendLater = () => {

    }

    return (
        <Modal
            show={isShowSendLaterModal}
            onHide={(e) => { dispatch(handleCloseModal()) }}
            backdrop="static"
            keyboard={false}
            size="md"
            centered
        >
            <Modal.Body className='bg-white'>
                <Modal.Title>Send Later</Modal.Title>
                <p className='modal-subtitle mt-5'>America standard time</p>
                <div className='form-group-md'>
                    <div className='row align-items-center'>
                        <div className='col-auto'>
                            <div className="form-check mb-0">
                                <input checked={currentSelectOption === 'tomorrow-morning' ? true : false} onClick={() => { setCurrentSelectOption('tomorrow-morning') }} className="form-check-input" type="radio" name="time" value="" id="tomorrow" />
                                <label className="form-check-label text-black" htmlFor="tomorrow">Tomorrow morning</label>
                            </div>
                        </div>
                        <div className="col-auto">
                            <label className="col-form-label fw-normal text-black p-0">21 Jul, 08:00</label>
                        </div>
                    </div>
                </div>
                <div className='form-group-md'>
                    <div className='row align-items-center'>
                        <div className='col-auto'>
                            <div className="form-check mb-0">
                                <input checked={currentSelectOption === 'monday-morning' ? true : false} onClick={() => { setCurrentSelectOption('monday-morning') }} className="form-check-input" type="radio" name="time" value="" id="monday" />
                                <label className="form-check-label text-black" htmlFor="monday">Monday morning</label>
                            </div>
                        </div>
                        <div className="col-auto">
                            <label className="col-form-label fw-normal text-black p-0">25 Jul, 08:00</label>
                        </div>
                    </div>
                </div>
                <div className="form-group-lg form-check form-switch form-check-reverse d-inline-block">
                    <label className="form-check-label text-primary" htmlFor="dateTime">Select date and time</label>
                    <input className="form-check-input" checked={isEnableSelectDate} onChange={() => { setIsEnableSelectDate(!isEnableSelectDate) }} type="checkbox" role="switch" id="dateTime" />
                </div>
                {
                    isEnableSelectDate &&
                    <div className='row'>
                        <div className="col-6">
                            <div className='form-group'>
                                <label className="form-label">Select Date</label>
                                <div className="input-icon">
                                    <span className='icon'><i className="icon-calendar"></i></span>
                                    <input type="date" className="form-control" placeholder="08/30/2022" />
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className='form-group'>
                                <label className="form-label">Select Time</label>
                                <div className="input-icon">
                                    <span className='icon'><i className="icon-clock"></i></span>
                                    <input type="time" className="form-control" placeholder="17 : 01" />
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className='form-group-md'>
                                <label className="form-label">Select TimeZone</label>
                                <div className="input-group">
                                    <span className="input-group-text bg-transparent border-right-0"><i className='icon-time-zone'></i></span>
                                    <select className="form-select">
                                        <option value="Pacific Time (US &amp; Canada)">(GMT-08:00) Pacific Time (America &amp; Los_Angeles)</option>
                                        <option value="Hawaii">(GMT-10:00) Hawaii</option>
                                        <option value="Alaska">(GMT-09:00) Alaska</option>
                                        <option value="Arizona">(GMT-07:00) Arizona</option>
                                        <option value="Mountain Time (US &amp; Canada)">(GMT-07:00) Mountain Time (US &amp; Canada)</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className="btn-toolbar justify-content-end">
                    <button className="btn btn-lg btn-link px-0" onClick={(e) => { dispatch(handleCloseModal()); setIsEnableSelectDate(false); setCurrentSelectOption(null); }} >Cancel</button>
                    <button className="btn btn-lg btn-secondary btn-gray-light" onClick={() => { sendLater() }} disabled={currentSelectOption === null ? true : false} >Send Later</button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default SendLaterModal;