import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import LoadingOverlay from 'react-loading-overlay-ts';
import EmailTopbar from '../EmailTopbar/EmailTopbar'
import DownloadIcon from '../DownloadIcon/DownloadIcon'
import { getGoogleThreads, batchTrashEmail } from "../../../api/index.js";
import { formatDate } from "../../../api/helper.js";
import { setEmails, onSelectChanged, setCurrentEmailBox, setIsSelectMenuItem } from "../../../action/index.js";
import { SvgAttachment } from '../../../utils/IconInfo';

const Drafts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [pageIndex, setPageIndex] = useState(1);
  const [loading, setLoading] = useState(true);

  const isSelectMenuItem = useSelector((state) => state.reducer.isSelectMenuItem);
  const props = useSelector((state) => state.reducer);
  const accountId = useSelector((state) => state.reducer.account_id);
  const emailAvailableMenus = useSelector((state) => state.reducer.emailAvailableMenus);
  const emailBoxMenuLoaded = useSelector((state) => state.reducer.emailBoxMenuLoaded);

  const fetchData = async () => {
    try {
      setLoading(true);
      const googleThreadsResponse = await getGoogleThreads(props.emailAvailableMenus.draft.id, props.pageTokens.draft[pageIndex], accountId);
      dispatch(setEmails('draft', googleThreadsResponse, pageIndex));
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  const onRefresh = () => {
    fetchData();
  }
  const onDelete = async () => {
    let emails = props.draft.emails;
    let threadIDs = [];
    emails.map(async (email) => {
      if (email.selected) {
        threadIDs.push(email.thread_id);
      }
    });
    if (threadIDs.length > 0) {
      try {
        dispatch(setEmails('draft', { emails: emails.filter((email) => !threadIDs.includes(email.thread_id)) }))
        await batchTrashEmail(threadIDs, accountId);
      } catch (err) {
        // error handling
      }
    }
  }
  const onPrevPage = () => {
    if (pageIndex > 1) {
      setPageIndex(pageIndex - 1);
    }
  }
  const onNextPage = () => {
    if (props.pageTokens.draft[pageIndex + 1]) {
      setPageIndex(pageIndex + 1);
    }
  }

  useEffect(() => {
    dispatch(setCurrentEmailBox('draft'))
  }, [])

  useEffect(() => {
    if (isSelectMenuItem) {
      setPageIndex(1);
    }
  }, [isSelectMenuItem])

  useEffect(() => {
    setLoading(true)
    if (accountId !== 0 && emailAvailableMenus.draft !== null) {
      fetchData();
      dispatch(setIsSelectMenuItem(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailAvailableMenus.draft, pageIndex]);

  return (
    <LoadingOverlay active={loading || !emailBoxMenuLoaded} spinner text='Loading ...' >
      <div className="section-content">
        <div className="media-list media-list-divided media-list-hover media-email">
          <EmailTopbar onRefresh={() => onRefresh()} onDelete={() => onDelete()} box="draft" pageIndex={pageIndex} onNextPage={() => onNextPage()} onPrevPage={() => onPrevPage()} />
          <div className="media-list-body scrollbar">
            <div className='media-title-header'><strong className='title'>Drafts</strong></div>

            {loading || !emailBoxMenuLoaded ? <div className="media media-draft">
              <BeatLoader color="#36d7b7" />
            </div>
              : props.draft.emails.length === 0 && !loading ?
                <div className="media media-draft">
                  There is no email in this box.
                </div>
                :
                props.draft.emails.map((thread) =>
                  <div className="media media-draft draft" key={thread.thread_id}>
                    <div className="form-check custom-checkbox mb-0">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={thread.selected ? true : false}
                        id={thread.thread_id}
                        onChange={(event) => dispatch(onSelectChanged(event, 'draft', thread.thread_id))}
                      />
                      <div style={{ display: "flex" }}>
                        {thread.to[0].name && <div className="form-check-label text-truncate" htmlFor={thread.thread_id}>{thread.to[0].name}</div>}
                        {thread.to[0].name && <p>,&nbsp; </p>}
                        <div className="form-check-label title-text-truncate" htmlFor={thread.thread_id} style={{ color: "#F07D12" }}>Draft</div>
                      </div>
                    </div>
                    <div className="content-data media-body" onClick={() => { navigate(`/email/thread/+${thread.thread_id}`); }} >
                      {(thread.subject === "" || thread.subject === null) ?
                        <strong className='media-subject content-title text-truncate d-block'> (no subject) </strong>
                        :
                        <strong className='media-subject content-title text-truncate d-block'> {thread.subject} </strong>
                      }
                      <p className='text-truncate m-auto content-text ps-10'>{thread.snippet}</p>
                    </div>
                    <div className='media-info'>
                      {thread.has_attachments && <span className="media-file"><SvgAttachment className='icon-download' width="12px" height='12px' /></span>}
                      <small className="media-dayTime">{formatDate(thread.date_time)}</small>
                    </div>
                  </div>
                )}

          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
}


export default Drafts;